import { useState } from "react";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { FaqCardProps } from "./types";

export const FaqCard = ({ question, answer }: FaqCardProps) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleAnswer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative rounded-[30px] bg-[#FFFFFF0D] px-16 py-8">
      <div className="flex items-start justify-between">
        <h3 className="text-left font-['PPRightGrotesk-WideMedium'] text-2xl font-black leading-[27.42px] text-primary-purple">
          {question}
        </h3>
        <button
          onClick={toggleAnswer}
          className="font-bold text-primary-purple"
        >
          {isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </button>
      </div>
      {typeof answer === "string" ? (
        <p className="pt-4 font-['Inter'] text-[14px] font-medium leading-[24px] tracking-[0.1px] !text-base-gray">
          {answer}
        </p>
      ) : (
        answer.map((item, index) => (
          <p
            key={index}
            className="pt-4 font-['Inter'] text-[14px] font-medium leading-[24px] tracking-[0.1px] !text-base-gray"
          >
            {item}
          </p>
        ))
      )}
    </div>
  );
};
