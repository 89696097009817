import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Typography, InputLabel, Box } from "@mui/material";
import { AuthProps } from "../../types/auth";
import {
  StyledAuthInputs,
  StyledAuthInputFields,
  StyledAuthButton,
} from "../micro/micro.styled";

import CloseCircle from "../../assets/close-circle.svg";
import { useDispatch } from "react-redux";
import { setLoginModalOpen } from "../../redux/actions/app";

export const EmailInvite = (props: AuthProps): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleUserExists = () => {
    navigate("/");
    dispatch(setLoginModalOpen(true));
  };

  const {
    onChangeHandler,
    userData,
    nextUserFunction,
    formError,
    userExists,
    onNextKeyPress,
    loading,
  } = props;

  useEffect(() => {
    localStorage.setItem("onboarding_in_progress", "true");
  }, []);

  return (
    <StyledAuthInputs>
      <Typography
        variant={"h2"}
        mb={5}
        mt={6}
        align={"left"}
        maxWidth={"221px"}
      >
        Where should we send your invite?
      </Typography>
      <Box display={"flex"} flexDirection={"column"} position={"relative"}>
        <InputLabel
          sx={{
            top: "-10px",
          }}
          htmlFor="email"
        >
          Email
        </InputLabel>
        <StyledAuthInputFields
          value={userData.email}
          onChange={onChangeHandler}
          onKeyDown={onNextKeyPress}
          type={"email"}
          name={"email"}
          error={formError !== ""}
          sx={{
            input: {
              paddingRight: "48px",
            },
          }}
        />
        {formError && (
          <Typography fontSize={12} color={"error"} variant={"body1"}>
            {formError}
            {userExists && (
              <span style={{ cursor: "pointer" }} onClick={handleUserExists}>
                {" "}
                Sign in.
              </span>
            )}
          </Typography>
        )}
        {formError !== "" && (
          <img
            style={{
              height: "16px",
              width: "16px",
              objectFit: "contain",
              position: "absolute",
              bottom: "36px",
              right: "16px",
            }}
            src={CloseCircle}
            alt=""
          />
        )}
      </Box>
      <StyledAuthButton
        sx={{
          marginTop: {
            sm: "45px",
          },
        }}
        onClick={() => nextUserFunction()}
        variant={"contained"}
        disabled={userData.email === "" || formError !== "" || loading}
      >
        {loading ? "Loading..." : "Save and continue"}
      </StyledAuthButton>
    </StyledAuthInputs>
  );
};
