import React from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router";
import { Typography } from "@mui/material";
import { setLoginModalOpen } from "../../redux/actions/app";
import { useDispatch } from "react-redux";
import { analyticsEvent } from "../../redux/actions/analytics";

export interface NavbarItemsProps {
  closeNavbar: () => void;
}

export const NavbarItems = ({ closeNavbar }: NavbarItemsProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const items = [
    {
      name: "Login",
      route: "/login",
    },
    {
      name: "Hardware",
      route: "/hardware",
      handleOnClick: () => {
        navigate("/hardware");
        closeNavbar();
      },
    },
    {
      name: "Pricing",
      route: "/pricing",
      handleOnClick: () => {
        navigate("/pricing");
        closeNavbar();
      },
    },
    {
      name: "Support",
      route: "#",
      handleOnClick: () => {
        dispatch(analyticsEvent("EVENT", "Support Button Clicked", {}));
        window.open(
          "http://support.density.one/hc/en-us/requests/new",
          "_blank",
        );
      },
    },
  ];
  const navList = {
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.2,
        staggerChildren: 0.07,
      },
    },
    hidden: {
      opacity: 0,
      transition: {
        staggerChildren: 0.05,
        staggerDirection: -1,
      },
    },
  };

  const navItem = {
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 },
      },
    },
    hidden: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000, velocity: -100 },
      },
    },
  };

  const handleClick = () => {
    dispatch(setLoginModalOpen(true));
    closeNavbar();
  };

  return (
    <motion.ul
      className="navList"
      initial="hidden"
      animate="visible"
      exit="hidden"
      variants={navList}
    >
      {items.map((item) =>
        item.name === "Login" ? (
          <motion.li variants={navItem} key={item.route} onClick={handleClick}>
            <Typography
              variant="subtitle2"
              sx={{
                fontSize: {
                  xs: "30px",
                  md: "60px",
                },
                lineHeight: {
                  xs: "40px",
                  md: "120px",
                },
                fontWeight: 900,
                cursor: "pointer",
              }}
              textAlign="center"
            >
              {item.name}
            </Typography>
          </motion.li>
        ) : (
          <motion.li
            variants={navItem}
            key={item.route}
            onClick={item.handleOnClick}
          >
            <Typography
              variant="subtitle2"
              sx={{
                fontSize: {
                  xs: "30px",
                  md: "60px",
                },
                lineHeight: {
                  xs: "40px",
                  md: "120px",
                },
                fontWeight: 900,
                cursor: "pointer",
              }}
              textAlign="center"
            >
              {item.name}
            </Typography>
          </motion.li>
        ),
      )}
    </motion.ul>
  );
};
