import { Typography } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import { LandingNavbar } from "../../components/LandingNavbar";
import {
  StyledDensityHardwareContainer,
  StyledLandingBoldParagraph,
  StyledLandingPageWrapper,
  StyledLandingVideoWrapper,
} from "./landing.styled";
import { useWindowDimensions } from "../../utils/hooks/windowDimensionsHook";
import { Footer } from "../../components/Landing/Footer";
import { useDispatch } from "react-redux";
import { InfoPanel } from "../../components/Info/InfoPanel";
import { TEXTS } from "./text";
import ModalVideo from "react-modal-video";
import NewCoverImage from "../../assets/new-cover-image.png";
import RectSetupStepOne from "../../assets/rec-setup-step-one.svg";
import RectSetupStepTwo from "../../assets/rec-setup-step-two.svg";
import RectSetupStepThree from "../../assets/rec-setup-step-three.svg";
import RectSetupStepFour from "../../assets/rec-setup-step-four.svg";
import RectSetupStepFive from "../../assets/rec-setup-step-five.svg";
import RectSetupStepSix from "../../assets/rec-setup-step-six.svg";
import RectSetupStepSeven from "../../assets/rec-setup-step-seven.svg";
import RectSetupStepEight from "../../assets/rec-setup-step-eight.svg";
import RectSetupStepFinal from "../../assets/rec-setup-final-step.svg";
import RetrievingSetsStepOne from "../../assets/retrieving-sets-step-one.svg";
import RetrievingSetsStepTwo from "../../assets/retrieving-sets-step-two.svg";
import RetrievingSetsStepThree from "../../assets/retrieving-sets-step-three.svg";
import RetrievingSetsStepFour from "../../assets/retrieving-sets-step-four.svg";
import RetrievingSetsStepFive from "../../assets/retrieving-sets-step-five.svg";
import OpenTheSet from "../../assets/open-the-set.svg";
import PlayIcon from "../../assets/Pause Controls.svg";
import FullSetup from "../../assets/rec-full-setup.svg";
import Mixer from "../../assets/mixer.svg";
import DJBooth from "../../assets/dj-booth.svg";
import MultiPort from "../../assets/multi-port.svg";
import Info from "../../assets/info.svg";
import { InfoShowcase } from "../../components/Info/InfoShowcase";
import { Header } from "../../components/Header";
import { useNavigate } from "react-router-dom";
import { AxiosResponse } from "axios";
import { api } from "../../api/base";
import { decodeToken } from "react-jwt";
import { analyticsEvent } from "../../redux/actions/analytics";
import { Step } from "../../components/Info/InfoPanel/Step";
import { FaqCard } from "../../components/Info/FaqCard";

export const RecSetupPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();

  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const contentLoaded = Boolean(localStorage.getItem("content-loaded"));
  const [loading, setLoading] = useState(!contentLoaded);
  const videoRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    dispatch(
      analyticsEvent("EVENT", "REC Setup Page Visited", {
        page: "REC Setup Page",
      }),
    );
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "scroll";
    }
    return () => {
      document.body.style.overflowY = "scroll";
    };
  }, [isOpen]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      if (currentScrollPos > prevScrollPos) {
        setVisible(true);
      } else {
        setVisible(false);
      }

      setPrevScrollPos(currentScrollPos);
    };
    window.addEventListener("scroll", handleScroll, true);

    if (loading) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 1500);

      setTimeout(() => {
        setLoading(false);
        localStorage.setItem("content-loaded", "true");
      }, 2500);
    }

    return () => window.removeEventListener("scroll", handleScroll, true);
  }, [dispatch, loading, prevScrollPos]);

  const buyHardwarePlan = async (): Promise<void> => {
    if (!localStorage.getItem("access_token")) {
      navigate("/onboarding/1?plan=hardware", { state: { from: "hardware" } });
      return;
    }
    setLoading(true);
    try {
      const token = localStorage.getItem("access_token");
      //@ts-ignore
      const decodedToken = decodeToken(token);
      //@ts-ignore
      const email = decodedToken?.email;
      const { data }: AxiosResponse<any, any> = await api.post(
        "chargebee/checkout-hardware",
        {
          redirect_url: `${process.env.REACT_APP_URL}/account/upgrade-success?plan=hardware`,
          email,
        },
      );
      navigate(`/account/checkout`, {
        state: {
          url: data.hosted_page.url,
          additionalText: "Hardware - $300",
          title: "REC-1",
        },
      });
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };
  const handlePurchaseAction = () => {
    buyHardwarePlan();
  };

  return (
    <>
      {loading && (
        <div className="landing-loader">
          <div className="left-loader-part"></div>
          <svg id="Layer_1" x="0px" y="0px" viewBox="0 0 1366 22">
            <polygon
              className="st0"
              points="685.3,1 685,1 0,1 0,11 685,11 692,11 692.1,11 "
            />
            <polygon
              className="st0"
              points="699,11 692,11 698.7,21 699,21 1366,21 1366,11 "
            />
          </svg>
          <div className="right-loader-part"></div>
        </div>
      )}
      <StyledLandingPageWrapper className="landing-page-wrapper">
        {localStorage.getItem("access_token") ? (
          <Header />
        ) : (
          <LandingNavbar
            visible={visible}
            ww={width}
            getDensity={() => {
              return handlePurchaseAction();
            }}
          />
        )}
        <main style={{ maxWidth: 1360, margin: "auto" }}>
          <StyledDensityHardwareContainer>
            <div className="mb-[146px] mt-[126px]">
              <Typography
                className="density-hero-heading hardware-density-heading"
                variant="h1"
                component="h1"
                sx={{
                  background:
                    "linear-gradient(182.83deg, rgba(218, 220, 240, 0.8) 12.57%, rgba(168, 171, 219, 0.5) 88.12%)",
                  "-webkit-background-clip": {
                    md: "text",
                    xs: "text",
                  },
                  "-webkit-text-fill-color": {
                    md: "transparent",
                    xs: "transparent",
                  },
                }}
              >
                Density Hardware. <br />
                Setup Guide
              </Typography>
            </div>
          </StyledDensityHardwareContainer>

          <section className="mb-[100px]">
            <StyledLandingVideoWrapper ref={videoRef}>
              <ModalVideo
                channel="youtube"
                isOpen={isOpen}
                videoId="Ix7_3nIACpU"
                youtube={{
                  autoplay: 1,
                  showinfo: 0,
                  controls: 1,
                }}
                onClose={() => setOpen(false)}
              />
              <img className="landing-video-image" src={NewCoverImage} alt="" />

              <button
                className="landing-video-play-icon"
                onClick={() => setOpen(true)}
              >
                <img
                  role="presentation"
                  src={PlayIcon}
                  onClick={() => setOpen(true)}
                  alt=""
                />
              </button>
              <StyledLandingBoldParagraph>
                {"Density explained in 90 seconds"}
              </StyledLandingBoldParagraph>
            </StyledLandingVideoWrapper>
          </section>

          <section className=" md:mt-24">
            <InfoPanel
              data={{ ...TEXTS.FIRST_INFO_PANEL }}
              className="rounded-[30px] bg-[#FFFFFF0D] p-12 pt-[79px]"
            >
              <img src={FullSetup} alt="the full setup" />
            </InfoPanel>
          </section>

          <section className="mt-[38px] md:mt-24">
            <div className="relative box-border flex w-[100%] w-[100%] flex-col items-center justify-center rounded-[30px] bg-[#FFFFFF0D] pb-[84px] text-center">
              <h2 className="py-[74px] text-center font-['PPRightGrotesk-Bold'] text-[34px] leading-[27.42px] text-primary-purple">
                Tech rider
              </h2>
              <div className="flex w-[100%] flex-col justify-between gap-12 md:flex-row md:gap-0 lg:px-[104px]">
                <div className="mx-auto flex flex-col gap-6 text-center text-base-gray md:text-left">
                  <img
                    src={Mixer}
                    alt="REC setup tech rider"
                    className="w-[250.77px]"
                  />
                  <p className="text-primary-purple">V10,900,A9, 750MK2</p>
                  <div className="flex w-[100%] justify-center gap-2 md:justify-start">
                    <img src={Info} className="w-[24px]" />
                    <p>No Xone.</p>
                  </div>
                </div>
                <div className="mx-auto flex flex-col gap-6 text-center text-base-gray md:text-left">
                  <img
                    src={DJBooth}
                    alt="REC setup tech rider"
                    className="w-[190.15px]"
                  />
                  <p className="text-primary-purple">
                    3000,200nxs2, 1000, 700, XZ
                  </p>
                  <div className="flex gap-2">
                    <img src={Info} className="w-[24px]" />
                    <p>No Traktor or Serato.</p>
                  </div>
                </div>
                <div className="mx-auto flex max-w-[273px] flex-col gap-6 text-left text-[14px] text-base-gray">
                  <div className="w-[273px]">
                    <img src={MultiPort} alt="REC setup tech rider" />
                  </div>
                  <div>
                    <p className="text-primary-purple">8 port network switch</p>
                    <p>4 CDJs + Mixer will fill a 5 port Switch.</p>
                  </div>
                  <p>
                    If the artist plans on setting up the recorder as they get
                    on stage, the network switch and power outlet need to be
                    easily accessible.
                  </p>
                </div>
              </div>
            </div>
          </section>

          <div className="my-[100px]">
            <Typography
              className="density-hero-heading hardware-density-heading"
              variant="h1"
              component="h1"
              sx={{
                background:
                  "linear-gradient(182.83deg, rgba(218, 220, 240, 0.8) 12.57%, rgba(168, 171, 219, 0.5) 88.12%)",
                "-webkit-background-clip": {
                  md: "text",
                  xs: "text",
                },
                "-webkit-text-fill-color": {
                  md: "transparent",
                  xs: "transparent",
                },
              }}
            >
              How to setup
            </Typography>
          </div>

          <section className="mt-[38px] md:mt-24">
            <div className="flex flex-wrap gap-4 ">
              <div className="relative box-border flex w-[100%] flex-col items-center justify-center rounded-[30px] bg-[#FFFFFF0D] text-center md:w-[calc(50%-0.5rem)]">
                <div className="mt-[168px] flex justify-center">
                  <img
                    src={RectSetupStepOne}
                    alt="REC setup step one"
                    className="w-[344px]"
                  />
                </div>
                <Step step={"1"} className="absolute left-[32px] top-[32px]" />

                <h3 className=" pt-[60px] text-center font-['PPRightGrotesk-Bold'] text-2xl leading-[27.42px] text-primary-purple">
                  Ensure the power cable is
                </h3>
                <h3 className=" font-['PPRightGrotesk-Bold'] text-2xl leading-[27.42px] text-primary-purple">
                  pushed all the way into the device.
                </h3>
                <p className="pt-4 text-[12px] text-base-gray">
                  If it is partially pushed in, the device will overheat and
                  shut off.
                </p>
                <p className="mb-[89px] text-[12px] text-base-gray">
                  After you ensure everything is in place, push the power
                  button.
                </p>
              </div>

              <div className="relative box-border flex w-[100%] flex-col items-center justify-center rounded-[30px] bg-[#FFFFFF0D] text-center md:w-[calc(50%-0.5rem)]">
                <div className="flex justify-center pt-[139px]">
                  <img
                    src={RectSetupStepTwo}
                    alt="REC setup step two"
                    className="w-[240px]"
                  />
                </div>
                <Step step={"2"} className="absolute left-[32px] top-[32px]" />

                <h3 className=" pt-[50px] text-center font-['PPRightGrotesk-Bold'] text-2xl leading-[27.42px] text-primary-purple">
                  When the device is
                </h3>
                <h3 className=" font-['PPRightGrotesk-Bold'] text-2xl leading-[27.42px] text-primary-purple">
                  ready, it will say:
                </h3>
                <span className="mb-[89px] flex gap-1 pt-4 text-[12px] text-base-gray">
                  <p>Mixer:</p>
                  <p className="text-primary-purple">Disconnected</p>
                  <p></p>
                  <p>Decks: </p>
                  <p className="text-primary-purple">Disconnected</p>
                </span>
              </div>

              <div className="relative box-border flex w-[100%] flex-col items-center justify-center rounded-[30px] bg-[#FFFFFF0D] text-center md:w-[calc(50%-0.5rem)]">
                <div className="flex justify-center pt-[139px]">
                  <img
                    src={RectSetupStepThree}
                    alt="REC setup step three"
                    className="w-[403.17px]"
                  />
                </div>
                <Step step={"3"} className="absolute left-[32px] top-[32px]" />

                <h3 className=" pt-[50px] text-center font-['PPRightGrotesk-Bold'] text-2xl leading-[27.42px] text-primary-purple">
                  Connect the Rec-1 via ethernet
                </h3>
                <h3 className=" font-['PPRightGrotesk-Bold'] text-2xl leading-[27.42px] text-primary-purple">
                  to the network switch.
                </h3>

                <span className="pt-4 text-[12px] text-base-gray">
                  It can take fourteen seconds to resolve on the network.
                </span>
                <span className="mb-[89px] flex gap-1 text-[12px] text-base-gray">
                  <p>When it’s done it will say</p>
                  <p className="text-primary-purple">Decks: Connected</p>
                </span>
              </div>

              <div className="relative box-border flex w-[100%] flex-col items-center justify-center rounded-[30px] bg-[#FFFFFF0D] text-center md:w-[calc(50%-0.5rem)]">
                <div className="flex justify-center pt-[139px]">
                  <img
                    src={RectSetupStepFour}
                    alt="REC setup step four"
                    className="w-[190.15px]"
                  />
                </div>
                <Step step={"4"} className="absolute left-[32px] top-[32px]" />

                <h3 className=" pt-[50px] text-center font-['PPRightGrotesk-Bold'] text-2xl leading-[27.42px] text-primary-purple">
                  Deck settings
                </h3>

                <span className="flex gap-1 pt-4 text-[12px] text-base-gray">
                  <p>Continuous mode:</p>
                  <p className="text-primary-purple"> Off</p>
                </span>
                <span className="mb-[89px] flex gap-1 text-[12px] text-base-gray">
                  <p>Auto-cue: </p>
                  <p className="text-primary-purple">On</p>
                </span>
              </div>

              <div className="relative box-border flex w-[100%] flex-col items-center rounded-[30px] bg-[#FFFFFF0D] text-center md:flex-row md:text-left">
                <div className="mx-[66px]">
                  {width < 539 ? (
                    <h3 className=" pt-[150px] text-left font-['PPRightGrotesk-Bold'] text-2xl leading-[27.42px] text-primary-purple md:pt-[50px]">
                      Connect the USB to the top LEFT of the mixer and press the
                      MIDI button directly underneath.
                    </h3>
                  ) : (
                    <>
                      <h3 className=" pt-[150px] font-['PPRightGrotesk-Bold'] text-2xl leading-[27.42px] text-primary-purple md:pt-[50px]">
                        Connect the USB to the top LEFT
                      </h3>
                      <h3 className=" font-['PPRightGrotesk-Bold'] text-2xl leading-[27.42px] text-primary-purple">
                        of the mixer and press the MIDI
                      </h3>
                      <h3 className=" font-['PPRightGrotesk-Bold'] text-2xl leading-[27.42px] text-primary-purple">
                        button directly underneath.
                      </h3>
                    </>
                  )}

                  <ul className="my-4 ml-8 max-w-[43ch] list-disc text-left font-['Inter'] text-[12px] font-medium leading-[18px] tracking-[0.1px] !text-base-gray">
                    <li>Move a fader to send midi data;</li>
                    <li>The Rec-1 will say Mixer Connected;</li>
                    <li>
                      If the mixer gets turned off, or loses power, the midi
                      button needs to be pressed again, or it will not record.
                    </li>
                  </ul>
                </div>
                <div className="mb-[102px] flex  justify-center pt-[82px]">
                  <img
                    src={RectSetupStepFive}
                    alt="REC setup step five"
                    className="w-[600px]"
                  />
                </div>
                <Step step={"5"} className="absolute left-[32px] top-[32px]" />
              </div>

              <div className="relative w-[100%] rounded-[30px] bg-[#FFFFFF0D]">
                <h3 className=" mb-[55px] pt-[174px] text-center font-['PPRightGrotesk-Bold'] text-2xl leading-[27.42px] text-primary-purple md:pt-[74px]">
                  Capture the initial FX settings
                </h3>

                <div className="flex w-[100%] flex-col justify-center gap-8 px-8 text-center md:flex-row">
                  <p className="m-auto max-w-[28ch] pt-4 text-center text-[12px] text-base-gray md:m-0">
                    Select HPF or LPF on the V10. Press the button even if it's
                    already set to correct setting. The recorder doesn't know
                    until the button is pressed.
                  </p>
                  <div className="-mb-[12px] flex">
                    <img
                      src={RectSetupStepSix}
                      alt="REC setup step five"
                      className="w-[700px]"
                    />
                  </div>
                  <div className="flex flex-col">
                    <span className="pt-4 text-[12px] text-base-gray">
                      Turn the effects selector.
                    </span>
                    <span className="text-[12px] text-base-gray">
                      The recorder doesn't know what
                    </span>
                    <span className="md: mb-0 mb-[100px] text-[12px] text-base-gray">
                      effect it's on until it's moved.
                    </span>
                  </div>
                </div>
                <Step step={"6"} className="absolute right-[32px] top-[32px]" />
              </div>

              <div className="relative box-border flex w-[100%] flex-col items-center rounded-[30px] bg-[#FFFFFF0D] text-center md:flex-row md:text-left">
                <div className="mx-[66px] min-w-fit">
                  {width < 768 ? (
                    <>
                      <h3 className=" pt-[150px] font-['PPRightGrotesk-Bold'] text-2xl leading-[27.42px] text-primary-purple md:pt-[50px]">
                        When the screen displays “Decks and Mixer Connected”,
                        it’s ready to record.
                      </h3>
                      <p className="pt-4 text-[12px] text-base-gray">
                        Data is only captured when something is happening on the
                        Decks. The device can be left on until showtime without
                        wasting space.
                      </p>
                    </>
                  ) : (
                    <>
                      <h3 className=" pt-[150px] font-['PPRightGrotesk-Bold'] text-2xl leading-[27.42px] text-primary-purple md:pt-[50px]">
                        When the screen displays
                      </h3>
                      <h3 className=" font-['PPRightGrotesk-Bold'] text-2xl leading-[27.42px] text-primary-purple ">
                        “Decks and Mixer Connected”,
                      </h3>
                      <h3 className=" font-['PPRightGrotesk-Bold'] text-2xl leading-[27.42px] text-primary-purple">
                        it’s ready to record.
                      </h3>
                      <div className="flex flex-col text-left">
                        <span className="pt-4 text-[12px] text-base-gray">
                          Data is only captured when something is happening on
                          the
                        </span>
                        <span className="text-[12px] text-base-gray">
                          Decks. The device can be left on until showtime
                          without
                        </span>
                        <span className="mb-6 text-[12px] text-base-gray md:mb-0">
                          wasting space.
                        </span>
                      </div>
                    </>
                  )}
                </div>
                <div className="mb-[102px] flex  w-[100%] justify-center pt-[82px]">
                  <img
                    src={RectSetupStepSeven}
                    alt="REC setup step seven"
                    className="w-[360px]"
                  />
                </div>
                <Step step={"7"} className="absolute left-[32px] top-[32px]" />
              </div>

              <div className="relative box-border flex w-[100%] flex-col-reverse items-center rounded-[30px] bg-[#FFFFFF0D] text-center md:flex-row md:text-left">
                <div className="flex w-[100%] justify-center md:pt-[134px]">
                  <img
                    src={RectSetupStepEight}
                    alt="REC setup step eight"
                    className="w-[603px]"
                  />
                </div>
                <div className="min-w-fit md:mr-[135px]">
                  <h3 className=" pt-[150px] font-['PPRightGrotesk-Bold'] text-2xl leading-[27.42px] text-primary-purple md:pt-[50px]">
                    Press and hold the button to
                  </h3>
                  <h3 className=" font-['PPRightGrotesk-Bold'] text-2xl leading-[27.42px] text-primary-purple ">
                    power down the device
                  </h3>

                  <div className="mb-10 flex flex-col md:mb-0">
                    <span className="pt-4 text-[12px] text-base-gray">
                      Pulling out the power cord will eventually damage the
                    </span>
                    <span className="text-[12px] text-base-gray">
                      device. Do this once per day. Letting the device run for
                    </span>
                    <span className="text-[12px] text-base-gray">
                      days may cause recording errors.
                    </span>
                  </div>
                </div>

                <Step step={"8"} className="absolute right-[32px] top-[32px]" />
              </div>

              <div className="relative box-border flex w-[100%] flex-col items-center rounded-[30px] bg-[#FFFFFF0D] text-center md:flex-row md:text-left">
                <div className="mx-[66px] min-w-fit">
                  {width < 768 ? (
                    <>
                      <h3 className=" pt-[150px] font-['PPRightGrotesk-Bold'] text-2xl leading-[27.42px] text-primary-purple md:pt-[50px]">
                        No audio is recorded, just the metadata.
                      </h3>
                      <p className="pt-4 text-[12px] text-base-gray">
                        When opening a set, the DJ points Density to their music
                        folder, and the set is recreated.
                      </p>
                    </>
                  ) : (
                    <>
                      <h3 className=" pt-[150px] font-['PPRightGrotesk-Bold'] text-2xl leading-[27.42px] text-primary-purple md:pt-[50px]">
                        No audio is recorded,
                      </h3>
                      <h3 className=" font-['PPRightGrotesk-Bold'] text-2xl leading-[27.42px] text-primary-purple ">
                        just the metadata.
                      </h3>
                      <div className="mb-[100px] flex flex-col md:mb-0">
                        <span className="pt-4 text-[12px] text-base-gray">
                          When opening a set, the DJ points Density to
                        </span>
                        <span className="text-[12px] text-base-gray">
                          their music folder, and the set is recreated.
                        </span>
                      </div>
                    </>
                  )}
                </div>
                <div className="-mr-2 flex w-[100%] justify-end pt-[82px]">
                  <img
                    src={RectSetupStepFinal}
                    alt="REC setup step seven"
                    className="w-[747px]"
                  />
                </div>
              </div>
            </div>
          </section>

          <StyledDensityHardwareContainer>
            <div className="my-[100px]">
              <Typography
                className="density-hero-heading hardware-density-heading"
                variant="h1"
                component="h1"
                sx={{
                  background:
                    "linear-gradient(182.83deg, rgba(218, 220, 240, 0.8) 12.57%, rgba(168, 171, 219, 0.5) 88.12%)",
                  "-webkit-background-clip": {
                    md: "text",
                    xs: "text",
                  },
                  "-webkit-text-fill-color": {
                    md: "transparent",
                    xs: "transparent",
                  },
                }}
              >
                Retrieving sets
              </Typography>
            </div>
          </StyledDensityHardwareContainer>

          <section className="mt-[38px] md:mt-24">
            <div className="flex flex-wrap gap-4 ">
              <div className="relative box-border flex w-[100%] flex-col rounded-[30px] bg-[#FFFFFF0D] pb-[119px] text-center md:w-[calc(50%-0.5rem)] md:pb-0">
                <Step
                  step={"1"}
                  className="absolute bottom-[32px] left-[32px]"
                />

                <h3 className=" pt-[50px] text-center font-['PPRightGrotesk-Bold'] text-2xl leading-[27.42px] text-primary-purple">
                  At the end of the night plug a
                </h3>
                <h3 className=" font-['PPRightGrotesk-Bold'] text-2xl leading-[27.42px] text-primary-purple">
                  USB stick into the Rec-1 to
                </h3>
                <h3 className=" text-center font-['PPRightGrotesk-Bold'] text-2xl leading-[27.42px] text-primary-purple">
                  retrieve the sets.
                </h3>
                <div className="my-8 flex justify-center">
                  <img
                    src={RetrievingSetsStepOne}
                    alt="Retriving sets step one"
                    className="w-[180px]"
                  />
                </div>
              </div>

              <div className="relative box-border flex w-[100%] flex-col rounded-[30px] bg-[#FFFFFF0D] pb-[119px] text-center md:w-[calc(50%-0.5rem)] md:pb-0">
                <Step
                  step={"2"}
                  className="absolute bottom-[32px] left-[32px]"
                />

                <h3 className=" pt-[50px] text-center font-['PPRightGrotesk-Bold'] text-2xl leading-[27.42px] text-primary-purple">
                  Do not remove the USB
                </h3>
                <h3 className=" font-['PPRightGrotesk-Bold'] text-2xl leading-[27.42px] text-primary-purple">
                  until it says “Done”.
                </h3>

                <p className="pt-4 text-[12px] text-base-gray">
                  The screen will let you know when it’s
                </p>
                <p className="text-[12px] text-base-gray">
                  safe to remove the USB stick. Removing the USB
                </p>
                <p className="text-[12px] text-base-gray">
                  before it says “Done” will damage the device.
                </p>

                <div className="my-8 flex justify-center">
                  <img
                    src={RetrievingSetsStepTwo}
                    alt="Retriving sets step two"
                    className="w-[180px]"
                  />
                </div>
              </div>

              <div className="relative box-border flex w-[100%] flex-col items-center rounded-[30px] bg-[#FFFFFF0D] pb-[154px] pt-[119px] text-center md:flex-row md:text-left">
                <div className="mx-[66px] min-w-fit">
                  <h3 className=" font-['PPRightGrotesk-Bold'] text-2xl leading-[27.42px] text-primary-purple md:pt-[50px]">
                    Open the Density software and
                  </h3>
                  <h3 className=" font-['PPRightGrotesk-Bold'] text-2xl leading-[27.42px] text-primary-purple ">
                    plug the USB into your computer.
                  </h3>
                  <ol className="my-4 ml-6 max-w-[45ch] list-decimal font-['Inter'] text-[12px] text-[12px] font-medium leading-[18px] tracking-[0.1px] !text-base-gray">
                    <li>The sets will automatically save to the computer.</li>
                    <li>
                      Click “Convert” to process your set. If the recording is
                      long this can take time.
                    </li>
                    <li>
                      If more than one DJ played, the sets will automatically
                      split.
                    </li>
                  </ol>
                </div>
                <div className="flex w-[100%] justify-center">
                  <img
                    src={RetrievingSetsStepThree}
                    alt="Retrieve sets step three"
                    className="w-[434px]"
                  />
                </div>
                <Step
                  step={"3"}
                  className="absolute bottom-[32px] left-[32px]"
                />
              </div>

              <div className="relative box-border flex w-[100%] flex-col rounded-[30px] bg-[#FFFFFF0D] pb-[49px] text-center md:w-[calc(50%-0.5rem)] md:pb-0">
                <Step
                  step={"4"}
                  className="absolute bottom-[32px] left-[32px]"
                />

                <h3 className=" mx-auto max-w-[18ch] pt-[50px] text-center font-['PPRightGrotesk-Bold'] text-2xl leading-[27.42px] text-primary-purple">
                  When opening a set, the DJ points Density to their music
                  folder, and the set is recreated.
                </h3>
                <div className="mb-[99px] mt-8 flex justify-center">
                  <img
                    src={RetrievingSetsStepFour}
                    alt="Retrieving sets step four"
                    className="w-[156px]"
                  />
                </div>
              </div>

              <div className="relative box-border flex w-[100%] flex-col rounded-[30px] bg-[#FFFFFF0D] pb-[49px] text-center md:w-[calc(50%-0.5rem)] md:pb-0">
                <Step
                  step={"5"}
                  className="absolute bottom-[32px] left-[32px]"
                />

                <h3 className=" mx-auto max-w-[20ch] pt-[50px] text-center font-['PPRightGrotesk-Bold'] text-2xl leading-[27.42px] text-primary-purple">
                  If you don’t have the music files, you can easily share the
                  set with the DJ.
                </h3>
                <div className="mb-[99px] mt-8 flex justify-center">
                  <img
                    src={RetrievingSetsStepFive}
                    alt="Retrieving sets step five"
                    className="w-[116px]"
                  />
                </div>
              </div>
            </div>
          </section>

          <div className="my-[100px]">
            <Typography
              className="density-hero-heading hardware-density-heading"
              variant="h1"
              component="h1"
              sx={{
                background:
                  "linear-gradient(182.83deg, rgba(218, 220, 240, 0.8) 12.57%, rgba(168, 171, 219, 0.5) 88.12%)",
                "-webkit-background-clip": {
                  md: "text",
                  xs: "text",
                },
                "-webkit-text-fill-color": {
                  md: "transparent",
                  xs: "transparent",
                },
              }}
            >
              Safe for Artists
            </Typography>
          </div>

          <section className="mt-[38px] md:mt-24">
            <InfoShowcase
              data={{ ...TEXTS.SAFE_FOR_ARTISTS }}
              className="relative w-[100%]"
              customClassName={"bg-[#FFFFFF0D]"}
            >
              <div className="flex justify-center pl-10 pt-10 pt-16">
                <img src={OpenTheSet} alt="Open the set" />
              </div>
            </InfoShowcase>
          </section>

          <div className="my-[100px]">
            <Typography
              className="density-hero-heading hardware-density-heading"
              variant="h1"
              component="h1"
              sx={{
                background:
                  "linear-gradient(182.83deg, rgba(218, 220, 240, 0.8) 12.57%, rgba(168, 171, 219, 0.5) 88.12%)",
                "-webkit-background-clip": {
                  md: "text",
                  xs: "text",
                },
                "-webkit-text-fill-color": {
                  md: "transparent",
                  xs: "transparent",
                },
              }}
            >
              FAQ
            </Typography>
          </div>

          <div className="flex flex-col gap-4">
            {TEXTS.FAQ.map((faq, index) => {
              return <FaqCard key={index} {...faq} />;
            })}
          </div>

          <section className="mb-[18px] mt-[118px] md:mt-24">
            <div className="text-center">
              <h3 className="font-['PPRightGrotesk-WideMedium'] text-2xl font-black leading-[27.42px] text-primary-purple">
                Contact For Issues/Questions
              </h3>
              <p className="mb-[118px] pt-4 font-['Inter'] text-[12px] text-[14px] font-medium leading-[24px] tracking-[0.1px] !text-base-gray">
                A real human DJ can help:{" "}
                <a
                  href="mailto:support@density.one"
                  className="!text-primary-purple"
                >
                  support@density.one
                </a>
              </p>
            </div>
          </section>

          <Footer />
        </main>
      </StyledLandingPageWrapper>
    </>
  );
};
