import { Box, Typography } from "@mui/material";
import { StyledAuthButton } from "../micro/micro.styled";

import LaptopTrialMobile from "../../assets/trial-mobile.png";
import LaptopTrial from "../../assets/trial-image.png";
import { StyledFreeTrialWrapper } from "../../pages/LandingPage/landing.styled";
import { useNavigate } from "react-router-dom";

export interface StartYourFreeTrialProps {
  width: number;
  getDensity?: () => void;
}

export const StartYourFreeTrial = ({
  width,
  getDensity,
}: StartYourFreeTrialProps) => {
  const navigate = useNavigate();
  return (
    // <StyledFreeTrialWrapper>
    <div className="relative mx-auto flex w-full flex-col justify-between overflow-hidden rounded-[30px] bg-gradient-to-b from-[#686FEF] to-[rgba(104,111,239,0)] pt-[50px] lg:flex-row">
      <div className="flex flex-col gap-[24px] p-[24px]">
        <Typography variant="subtitle2" className="text-center text-[34px]">
          Give it a try
        </Typography>
        <StyledAuthButton
          variant="contained"
          color="primary"
          onClick={getDensity || (() => navigate("/login"))}
        >
          Download Density
        </StyledAuthButton>
        <a
          href="/pricing"
          className="text-center font-inter text-[14px] leading-[24px] tracking-wide !text-primary-purple underline lg:mt-[24px] lg:text-left"
        >
          Pricing
        </a>
      </div>

      <img
        src={width > 769 ? LaptopTrial : LaptopTrialMobile}
        alt=""
        className="lg:w-[600px]"
      />
    </div>
  );
};
