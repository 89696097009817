import { useState, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Lottie from "lottie-react";
import MainCoverImage from "../../assets/main-cover.webp";
import CoverImage from "../../assets/new_cover_image.webp";
import { LandingNavbar } from "../../components/LandingNavbar";
import PlayIcon from "../../assets/Pause Controls.svg";
import { useWindowDimensions } from "../../utils/hooks/windowDimensionsHook";
import { StyledAuthButton } from "../../components/micro/micro.styled";
import ModalVideo from "react-modal-video";
import { useCookies } from "react-cookie";

import LightEffect1 from "../../assets/light-eff-1.png";
import LightEffect2 from "../../assets/light-eff-2-e.png";
import LightEffect3 from "../../assets/light-eff-3.png";
import LightEffect4 from "../../assets/light-eff-4.png";
import LightEffect5 from "../../assets/light-eff-5.png";

import MLightEffect1 from "../../assets/m-light-eff-1.png";
import MLightEffect2 from "../../assets/m-light-eff-2.png";
import MLightEffect3 from "../../assets/m-light-eff-3.png";
import MLightEffect4 from "../../assets/m-light-eff-4.png";
import MLightEffect5 from "../../assets/m-light-eff-5.png";

import FullSetup from "../../assets/full-setup-desktop.svg";
import FullSetupMobile from "../../assets/full-setup-mobile.svg";
import EditImage from "../../assets/edit-set-img.png";
import EditImageMobile from "../../assets/Second block mobile.png";
import MixerData from "../../assets/mixer-set-data.png";
import MixerDataMobile from "../../assets/Third block mobile.png";
import TracksPreserved from "../../assets/tracks-preserved.png";
import Hardware from "../../assets/hardware 1.png";
import Decks from "../../assets/decks 3.png";
import Laptop from "../../assets/laptop.png";
import HardwareImage from "../../assets/hardware-new.svg";
import Flight1 from "../../assets/flight 1.webp";
import FlightMobile from "../../assets/dj-plane-mobile.png";
import EQLanes from "../../assets/EQ lanes 1.png";
import FX from "../../assets/Fx_2 1.png";
import { MasterSet } from "../../components/Landing/MasterSet";
import { StartYourFreeTrial } from "../../components/Landing/StartYourFreeTrial";
import { Footer } from "../../components/Landing/Footer";

import FirstAnimation from "../../assets/lottie/Page00--3720x3588.json";
import SecondAnimation from "../../assets/lottie/Page1--7224x1860.json";
import ThirdAnimation from "../../assets/lottie/Page2--4170x2364.json";
import FourthAnimation from "../../assets/lottie/Page3--3360x1980.json";

import "./styles.css";
import { useDispatch } from "react-redux";
import { analyticsEvent } from "../../redux/actions/analytics";
import { Link } from "@mui/material";
import { Header } from "../../components/Header";
import { useUserContext } from "../../context/UserContext";
import { GTMEvent, gtmTriggerEvent } from "../../utils/gtm";

export const Landing = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [startTime, setStartTime] = useState<number | null>(null);
  const contentLoaded = Boolean(localStorage.getItem("content-loaded"));
  const [loading, setLoading] = useState(!contentLoaded);
  const [cookies, setCookie] = useCookies();
  const [searchParams] = useSearchParams();
  const { isUserLoggedIn, fetchingUserData } = useUserContext();

  useEffect(() => {
    dispatch(
      analyticsEvent("VISIT", "Landing Page Visited", {
        page: "Landing Page",
      }),
    );
    dispatch({ type: "LANDING_VISITED", payload: { fbp: cookies._fbp } });
    gtmTriggerEvent(GTMEvent.LandingPageVisited);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      if (currentScrollPos > prevScrollPos) {
        setVisible(true);
      } else {
        setVisible(false);
      }

      setPrevScrollPos(currentScrollPos);
    };
    window.addEventListener("scroll", handleScroll, true);

    if (loading) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 1500);

      setTimeout(() => {
        setLoading(false);
        localStorage.setItem("content-loaded", "true");
      }, 2500);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll, true);
      if (isOpen) {
        const currentTime = new Date().getTime();
        let timeWatched = null;
        if (startTime !== null) {
          timeWatched = currentTime - startTime;
        }
        dispatch(
          analyticsEvent("CLICK", "Closed Explainer Video", {
            page: "Landing Page",
            timeWatched: timeWatched,
          }),
        );
      }
    };
  }, [loading]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "scroll";
    }
    return () => {
      document.body.style.overflowY = "scroll";
    };
  }, [isOpen]);

  const getDensity = () => {
    const flow = props.getDensity ? "invited" : "primary"; // Determine the flow based on the URL

    if (props.getDensity) {
      props.getDensity();
    } else {
      localStorage.removeItem("landing");
      dispatch(
        analyticsEvent("CLICK", "Get Density Clicked", {
          page: "Landing Page",
          flow: flow, // Use the determined flow
        }),
      );

      const ref = searchParams.get("ref");

      if (ref) {
        navigate(`onboarding/0/${ref}`);
      } else {
        navigate(`onboarding/1`);
      }
    }
  };

  const handleExplainerOpen = () => {
    const currentTime = new Date().getTime();
    setStartTime(currentTime);
    dispatch(
      analyticsEvent("CLICK", "Started Explainer Video", {
        page: "Landing Page",
      }),
    );
    setOpen(true);
  };

  const handleExplainerClose = () => {
    const currentTime = new Date().getTime();
    let timeWatched = null;
    if (startTime !== null) {
      const timeDiff = currentTime - startTime;
      const minutes = Math.floor(timeDiff / 60000);
      const seconds = Math.floor((timeDiff % 60000) / 1000);
      timeWatched = `${minutes}m ${seconds}s`;
    }
    dispatch(
      analyticsEvent("CLICK", "Closed Explainer Video", {
        page: "Landing Page",
        timeWatched: timeWatched,
      }),
    );
    setOpen(false);
  };

  return (
    <>
      {loading &&
        !contentLoaded &&
        localStorage.getItem("content-loaded") !== "true" && (
          // Loading Section
          <div className="landing-loader">
            <div className="left-loader-part"></div>
            <svg id="Layer_1" x="0px" y="0px" viewBox="0 0 1366 22">
              <polygon
                className="st0"
                points="685.3,1 685,1 0,1 0,11 685,11 692,11 692.1,11 "
              />
              <polygon
                className="st0"
                points="699,11 692,11 698.7,21 699,21 1366,21 1366,11 "
              />
            </svg>
            <div className="right-loader-part"></div>
          </div>
        )}
      {/* Light Effects */}
      <img
        src={MLightEffect1}
        className="absolute top-0 mx-auto flex h-auto h-auto object-fill lg:hidden"
        alt=""
      />
      {/* Navbar */}
      {isUserLoggedIn ? (
        <Header absolute />
      ) : (
        <LandingNavbar
          isOnboarding={props.isOnboarding}
          ww={width}
          visible={visible}
          getDensity={getDensity}
        />
      )}

      <div className="bg-[#020203]">
        {/* Main Cover Section */}
        <div className="relative flex h-full flex-col items-center p-5 md:p-0">
          <img
            src={MainCoverImage}
            className="absolute left-0 top-0 z-0 hidden md:block"
            alt=""
          />
          <div className="z-[1] mx-auto flex flex-col items-center md:px-[80px] lg:max-w-[1360px]">
            <h1 className="mt-[84px] inline-block bg-gradient-to-b from-[#DDDEF1CC] from-0% to-[#989CD573] to-80% bg-clip-text text-center font-PPRightGrotesk-Bold text-3xl text-[30px] text-transparent md:mt-[168px] md:text-[60px] md:leading-[68.55px] xl:max-w-[990px]">
              Density allows you to capture a DJ set and edit it after
            </h1>
            <StyledAuthButton
              variant="contained"
              color="primary"
              className="density-hero-button"
              onClick={getDensity}
              sx={{
                width: {
                  md: 220,
                  xs: 220,
                },
                height: {
                  md: 44,
                  xs: 36,
                },
                padding: {
                  xs: "10px 16px",
                  md: "15px",
                },
                marginLeft: {
                  xs: "0px",
                  md: "40px",
                },
                marginRight: {
                  xs: "0px",
                  md: "40px",
                },
                backgroundColor: "#c1c5dd",
                mt: {
                  xs: "25px",
                  md: "56px",
                },
                mb: "0px",
                fontSize: {
                  md: "13px",
                },
              }}
            >
              Download Density
            </StyledAuthButton>
          </div>
          {/* Video Section */}
          <div className="custom-youtube-header relative mx-auto mt-[68px] flex h-[165px] w-full max-w-[1100px] items-center justify-end overflow-hidden rounded-[10px] md:h-[450px] md:w-[calc(100%-160px)] lg:rounded-[30px] xl:mt-[100px] xl:h-[650px]">
            <ModalVideo
              channel="youtube"
              isOpen={isOpen}
              videoId="Ix7_3nIACpU"
              youtube={{
                autoplay: 1,
                showinfo: 0,
                controls: 1,
              }}
              onClose={handleExplainerClose}
            />
            <img
              className="custom-youtube-cover absolute left-0 top-0 h-[165px] w-full object-cover md:h-full"
              src={CoverImage}
              alt=""
            />
            <button
              className="absolute left-1/2 top-1/2 z-10 h-[44px] w-[44px] -translate-x-1/2 -translate-y-1/2 transform focus:outline-none lg:h-[88px] lg:w-[88px]"
              onClick={handleExplainerOpen}
              type="button"
              name="play-button"
            >
              <img
                role="presentation"
                src={PlayIcon}
                onClick={handleExplainerOpen}
                alt=""
              />
            </button>
            <p className="video-play-text-gradient z-10 mx-auto mt-auto inline-block font-PPRightGrotesk-WideMedium text-sm leading-7 text-transparent md:mt-[8px] md:max-w-[235px] lg:mx-0 lg:text-2xl xl:mr-[75px] xl:max-w-[235px]">
              Density explained in 90 seconds
            </p>
          </div>
        </div>

        <div className="mx-auto mt-[28px] flex w-full flex-col gap-[38px] p-5 lg:mt-[100px] lg:max-w-[1360px] lg:gap-[100px] lg:p-0 lg:px-[80px]">
          {/* Feature Section 1 */}
          <div className="flex flex-col overflow-hidden rounded-[20px] bg-[#FFFFFF0D] px-[30px] pt-[60px] lg:h-[627px] lg:flex-row lg:rounded-[30px] lg:pl-[74px] lg:pr-[63px] lg:pt-0">
            <div className="relative order-2 m-auto h-full w-full shrink-0 object-contain sm:h-[300px] md:h-[400px] md:w-[450px] lg:-bottom-1 lg:order-1 lg:h-[660px] xl:w-[616px]">
              <Lottie
                animationData={FirstAnimation}
                loop={true}
                className="h-full w-full"
              />
            </div>
            <div className="order-1 mb-[40px] self-center lg:order-2 lg:mb-0 lg:ml-[67px]">
              <p className="mb-[16px] text-center font-PPRightGrotesk-Bold text-[21px] text-primary-purple lg:mb-[10px] lg:text-left lg:text-[35px] lg:leading-[39.99px]">
                Density captures the data of a DJ set, not the audio
              </p>
              <p className="text-center font-inter text-[12px] font-medium leading-5 text-base-gray lg:text-left lg:text-[14px] lg:leading-[24px]">
                Density connects to club standard DJ decks to capture a set.
                Rather than recording audio, Density captures the data: every
                cue, loop, and knob twist.
              </p>
            </div>
          </div>

          {/* Light Effects */}
          <img
            src={LightEffect1}
            className="absolute hidden object-fill object-left lg:left-0 lg:top-[1636px] lg:block"
            alt=""
          />
          <img
            src={MLightEffect3}
            className="absolute left-0 top-[1017px] flex h-auto object-fill lg:hidden"
            alt=""
          />

          {/* Feature Section 2 */}
          <div className="flex flex-col overflow-hidden rounded-[20px] bg-[#FFFFFF0D] pb-[40px] pt-[60px] lg:h-[550px] lg:flex-row lg:rounded-[30px] lg:pb-[0px] lg:pl-[74px] lg:pr-0 lg:pt-0">
            <div className="mb-[40px] self-center px-[30px] lg:mb-0 lg:px-0 xl:mr-[30px]">
              <p className="mb-[16px] font-PPRightGrotesk-Bold text-[21px] text-primary-purple lg:text-[35px] lg:leading-[39.99px]">
                Edit your set after {width > 769 && <br></br>} in the Density
                app
              </p>
              <p className="font-inter text-[12px] font-medium leading-5 text-base-gray lg:text-[14px] lg:leading-[24px]">
                When you’re done capturing, you’ll see a recreation of the
                entire set where you can non-destructively edit transitions.
                Density is designed from the ground up for DJ mixes, making the
                workflow fast and intuitive.
              </p>
            </div>
            <img
              src={width > 769 ? EditImage : EditImageMobile}
              className="h-[148px] w-[220px] self-center md:h-[350px] md:w-[100%] md:object-contain lg:h-[400px] lg:w-[692.63px] lg:self-end xl:h-[500px]"
              alt=""
            />
          </div>

          {/* Feature Section 3 */}
          <div className="flex flex-col overflow-hidden rounded-[20px] bg-[#FFFFFF0D] pb-0 pt-[60px] lg:h-[550px] lg:flex-row lg:rounded-[30px] lg:pr-[58px] lg:pt-0">
            {/* TODO: NEW MIXER DATA IMAGE IS OFF */}
            <img
              src={width > 1024 ? MixerData : MixerDataMobile}
              className="order-2 h-[220px] w-full sm:h-[300px] sm:object-contain sm:object-right lg:order-1 lg:h-[550px] lg:w-[500px] lg:self-start lg:object-cover lg:object-right xl:w-[692px]"
              alt=""
            />
            <div className="order-1 mb-[40px] self-center px-[30px] text-center lg:order-2 lg:mb-0 lg:ml-[61px] lg:px-0 lg:text-left">
              <p className="mb-[10px] mb-[16px] text-center font-PPRightGrotesk-Bold text-[21px] text-primary-purple lg:text-left lg:text-[35px] lg:leading-[39.99px]">
                Mixer data is captured and editable
              </p>
              <p className="text-center font-inter text-[12px] font-medium leading-5 text-base-gray lg:text-left lg:text-[14px] lg:leading-[24px]">
                Density does not record audio, so your EQs are not baked into
                the waveform. Edit transitions, EQs, filters, and FX.
              </p>
            </div>
          </div>

          {/* Feature Section 4 */}

          <div className="flex flex-col overflow-hidden rounded-[20px] bg-[#FFFFFF0D] pb-[0px] pt-[60px] lg:h-[550px] lg:flex-row lg:rounded-[30px] lg:pb-[0px] lg:pl-[74px] lg:pr-0 lg:pt-0">
            <div className="mb-[40px] self-center px-[30px] lg:mb-0 lg:mr-[30px] lg:px-0">
              <p className="mb-[16px] text-center font-PPRightGrotesk-Bold text-[21px] text-primary-purple md:text-left lg:text-[35px] lg:leading-[39.99px]">
                Mastering-grade tracks are preserved
              </p>
              <p className="text-center font-inter text-[12px] font-medium leading-5 text-base-gray md:text-left lg:text-[14px] lg:leading-[24px]">
                When a set is opened, you’ll be asked to locate the audio files
                on your hard-drive. Your set is recreated using those files,
                preserving the mastering-grade tracks, which have never been
                distorted by DJ hardware.
              </p>
            </div>
            <img
              src={TracksPreserved}
              className="h-[173px] w-[250px] self-end object-right md:h-[350px] md:w-[100%] md:object-contain lg:h-[400px] lg:w-[683px] xl:h-[474px]"
              alt=""
            />
          </div>

          {/* Light Effects */}
          <img
            src={LightEffect3}
            className="absolute hidden object-fill object-right lg:right-0 lg:top-[3020px] lg:block"
            alt=""
          />
          <img
            src={MLightEffect4}
            className="absolute left-0 top-[2027px] flex h-auto object-fill lg:hidden"
            alt=""
          />

          {/* Three Ways to Capture Section */}
          <div style={{ display: "none" }}>
            {/* Mobile Version */}
            <div className="flex hidden flex-col">
              <p className="mb-[40px] bg-gradient-to-b from-[#DDDEF1CC] from-0% to-[#989CD573] to-80% bg-clip-text text-center font-PPRightGrotesk-Bold text-[30px] leading-[32px] text-transparent lg:text-6xl xl:max-w-[800px]">
                Two ways to capture a set
              </p>
              <div className="flex flex-col items-center rounded-[20px] bg-[#FFFFFF0D] px-[45px] pt-[30px] lg:rounded-[30px]">
                <p className="mb-[16px] text-center font-ibmPlexMono text-[11px] font-semibold tracking-[1.25px] text-[#575F9E]">
                  RECORD LIVE
                </p>
                <p className="mb-[16px] text-center font-PPRightGrotesk-Bold text-[21px] text-primary-purple">
                  With Density hardware
                </p>
                <p className="mb-6 max-w-[220px] text-center font-inter text-[12px] font-medium leading-[20px] text-base-gray lg:text-xs lg:leading-[18px]">
                  For club/festival set capturing, connect the Density hardware
                  to the decks.
                </p>
                <img
                  src={Hardware}
                  className="mt-auto h-[226px] w-[186px] object-contain"
                  alt=""
                />
              </div>
              <div className="my-[28px]">
                <p className="my-3 text-center font-inter text-xs font-medium text-base-gray">
                  OR
                </p>
              </div>
              <div className="flex flex-col items-center rounded-[20px] bg-[#FFFFFF0D] px-[45px] pt-[30px] lg:rounded-[30px]">
                <p className="mb-[16px] text-center font-ibmPlexMono text-[11px] font-semibold tracking-[1.25px] text-[#575F9E]">
                  RECORD LIVE
                </p>
                <p className="mb-[16px] text-center font-PPRightGrotesk-Bold text-[21px] text-primary-purple">
                  With laptop and decks
                </p>
                <p className="mb-6 max-w-[226px] text-center font-inter text-[12px] font-medium leading-[20px] text-base-gray lg:text-xs lg:leading-[18px]">
                  For home/studio set capturing, connect a laptop to your decks.
                </p>
                <img
                  src={Decks}
                  className="mt-auto h-[220px] w-[186px] object-contain lg:h-[240px]"
                  alt=""
                />
              </div>
              <div className="mt-[32px] rounded-[20px] bg-[#FFFFFF0D] pb-[40px] pt-[30px] lg:rounded-[30px]">
                <p className="mb-[16px] text-center font-ibmPlexMono text-[11px] font-semibold tracking-[1.25px] text-[#575F9E]">
                  POINT AND CLICK
                </p>
                <p className="mb-[16px] text-center font-PPRightGrotesk-Bold text-[21px] text-primary-purple">
                  With laptop only
                </p>
                <p className="mx-auto mb-6 max-w-[220px] text-center font-inter text-[12px] font-medium leading-[20px] text-base-gray lg:leading-[18px]">
                  Every track loads with EQ automation lanes ready to go.
                </p>
                <img
                  src={Laptop}
                  className="mx-auto h-[110px] w-[170px] object-contain"
                  alt=""
                />
              </div>
            </div>
          </div>

          {/* Booth Ready Section */}
          <div className="flex-col rounded-[20px] bg-[#FFFFFF0D] px-[20px] pb-[20px] pt-[30px] lg:flex lg:rounded-[50px] lg:pb-[80px] lg:pl-[80px] lg:pr-[80px] lg:pt-[40px]">
            <div className="flex flex-col lg:flex-row">
              <img
                src={HardwareImage}
                className="order-2 w-[60%] max-w-full -translate-x-[24px] self-center object-contain lg:order-1 lg:h-auto lg:w-[44%]"
                alt=""
              />
              <div className="order-1 mx-auto self-center px-[30px] lg:order-2 lg:mt-[-80px] lg:px-0">
                <p className="mb-[16px] text-center font-PPRightGrotesk-Bold text-[21px] text-primary-purple lg:max-w-[400px] lg:text-left lg:text-[34px] lg:font-bold lg:leading-[38.85px]">
                  Use the Density Hardware to capture a set
                </p>
                <p className="useDensityHardwareText mx-auto mb-0 max-w-[412px] text-center font-inter text-[12px] font-medium leading-6 text-base-gray lg:mx-0 lg:mb-0 lg:mb-[40px] lg:max-w-[auto] lg:text-left lg:text-sm">
                  Connect it to the ethernet switch and it’s good to go. Never
                  lose a set to recording errors again.
                </p>
                <div className="mb-10 text-center lg:text-left">
                  <Link
                    onClick={() => {
                      navigate("hardware");
                      window.scrollTo(0, 0);
                    }}
                    variant="body2"
                    className="cursor-pointer text-left font-inter text-base text-base font-medium leading-6 leading-6 tracking-wider !text-primary-purple"
                    sx={{
                      fontFamily: "Inter !important",
                      fontSize: "14px !important",
                      fontWeight: 500,
                      lineHeight: "24px",
                      letterSpacing: "0.0125rem",
                      textAlign: "left !important",
                    }}
                    target="_blank"
                  >
                    Learn more
                  </Link>
                </div>
              </div>
            </div>
            <div className="mt-10 flex flex-col items-center lg:mt-[0px]">
              <p className="mb-[26px] text-center font-PPRightGrotesk-Bold text-[21px] text-primary-purple lg:mb-[60px] lg:text-4xl">
                The full setup
              </p>
              <img
                src={FullSetup}
                className="hidden w-full max-w-full object-contain lg:block"
                alt=""
              />
              <img
                src={FullSetupMobile}
                className="block w-full max-w-full object-contain lg:hidden"
                alt=""
              />
              <p className="text=[#7F849B] mr-[0px] mt-[17px] max-w-[234px] text-center text-[12px] leading-[18px]">
                If you don’t have the Density Hardware, a laptop can be used to
                capture a set
              </p>
            </div>
          </div>

          {/* Standalone App Section */}
          <div className="relative flex h-[550px] w-full overflow-hidden rounded-[20px] lg:rounded-[30px]">
            <img
              src={width > 769 ? Flight1 : FlightMobile}
              className="object-fit absolute left-0 top-0 h-full w-full md:object-contain"
              alt=""
            />
            <div className="z-10 mt-[40px] self-start pl-[30px] pr-[30px] pt-[30px] sm:pt-[100px] lg:mt-0 lg:w-2/3 lg:self-center lg:pl-[90px] lg:pr-0 lg:pt-0">
              <p className="mb-[16px] font-ibmPlexMono text-[11px] font-semibold tracking-[1.25px] text-[#575F9E] lg:mb-6 lg:text-xs">
                DESKTOP SOFTWARE
              </p>
              <p className="mb-[16px] bg-gradient-to-r from-[#C2C3D5] from-0% to-[#4D4D55] to-80% bg-clip-text font-PPRightGrotesk-Bold text-[21px] text-primary-purple lg:from-[#4D4D55] lg:from-0% lg:to-[#C2C3D5] lg:to-80% lg:text-4xl lg:text-transparent">
                Works as a standalone app
              </p>
              <p className="max-w-[350px] font-inter text-[12px] font-medium leading-[20px] text-base-gray lg:max-w-[511px] lg:text-sm lg:leading-[24px]">
                If you don’t have access to DJ decks, Density works as as
                standalone Mac desktop app. You can point and click your way to
                a refined studio set without the need for hardware.
              </p>
            </div>
          </div>

          {/* Light Effects */}
          <img
            src={LightEffect4}
            className="absolute right-0 mx-auto hidden object-fill object-center lg:top-[4448px] lg:block"
            alt=""
          />
          <img
            src={MLightEffect5}
            className="absolute left-0 top-[4732px] flex h-auto object-fill lg:hidden"
            alt=""
          />

          {/* Painless Set Editing Section */}
          <div className="flex flex-col items-center">
            <p className="bg-gradient-to-b from-[#DDDEF1CC] from-0% to-[#989CD573] to-80% bg-clip-text text-center font-PPRightGrotesk-Bold text-[30px] leading-[32px] text-transparent lg:text-6xl xl:max-w-[800px]">
              Designed to make set editing painless
            </p>
            <StyledAuthButton
              variant="contained"
              color="primary"
              className="density-hero-button"
              onClick={getDensity}
              sx={{
                width: 220,
                height: {
                  md: 44,
                  xs: 36,
                },
                padding: {
                  xs: "10px 16px",
                  md: "15px",
                },
                backgroundColor: "#c1c5dd",
                mt: "50px",
                mb: "0px",
              }}
            >
              Download Density
            </StyledAuthButton>
          </div>

          {/* Feature Section 4 */}
          <div className="flex flex-col gap-[68px] lg:gap-4">
            <div className="flex flex-col rounded-[20px] bg-[#FFFFFF0D] pb-[40px] pt-[60px] lg:h-[627px] lg:rounded-[30px] lg:py-[65px]">
              <div className="order-2 h-[100px] lg:order-1 lg:h-[310px]">
                <Lottie
                  animationData={SecondAnimation}
                  loop={true}
                  className="h-full w-full"
                />
              </div>
              <div className="order-1 mx-auto mb-[40px] flex max-w-[380px] flex-col items-center justify-center px-[30px] lg:order-2 lg:mb-0 lg:mt-14 lg:px-0">
                <p className="mb-[16px] max-w-[365px] text-left font-PPRightGrotesk-Bold text-[21px] text-primary-purple lg:text-center lg:text-2xl">
                  Moving a track preserves the downstream transitions
                </p>
                <p className="max-w-[356px] text-left font-inter text-[12px] font-medium leading-5 text-base-gray lg:text-center lg:text-[13px] lg:leading-[22px]">
                  Any change you make to the set, will preserve all of your
                  later transitions. This works for moving, reordering and
                  removing tracks.
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-[68px] lg:h-[627px] lg:flex-row lg:gap-4">
              <div className="flex w-full flex-col rounded-[20px] bg-[#FFFFFF0D] pb-10 lg:w-3/5 lg:rounded-[30px] lg:pb-0 lg:pt-12">
                <div className="order-2 h-[158px] lg:order-1 lg:h-[394px]">
                  <Lottie
                    animationData={ThirdAnimation}
                    loop={true}
                    className="h-full w-full"
                  />
                </div>
                <div className="order-1 mx-auto mb-[40px] mt-[60px] flex max-w-[380px] flex-col items-center justify-center px-[30px] lg:order-2 lg:mb-0 lg:mt-10 lg:px-0">
                  <p className="mb-[16px] text-center font-PPRightGrotesk-Bold text-2xl text-primary-purple">
                    Transition Presets
                  </p>
                  <p className="text-center font-inter text-xs font-medium leading-5 text-base-gray lg:text-[13px] lg:leading-[22px]">
                    Cycle between different types of transitions and then adjust
                    from there. This saves around 20 clicks per transition.
                  </p>
                </div>
              </div>
              <div className="flex w-full flex-col overflow-hidden rounded-[20px] bg-[#FFFFFF0D] lg:w-2/5 lg:rounded-[30px]">
                <div className="mx-auto mt-20 flex flex-col items-center justify-center px-[40px] pb-10 lg:max-w-[275px] lg:px-0 lg:pb-0">
                  <p className="mb-[16px] text-center font-PPRightGrotesk-Bold text-2xl text-primary-purple">
                    EQ Automation Lanes
                  </p>
                  <p className="text-center font-inter text-[12px] font-medium leading-5 text-base-gray lg:text-[13px] lg:leading-[22px]">
                    Every track loads with EQ automation lanes ready to go.
                  </p>
                </div>
                <img
                  src={EQLanes}
                  className="mt-auto h-[258px] w-[250px] self-end object-contain lg:h-[418px] lg:w-[405px]"
                  alt=""
                />
              </div>
            </div>
            <img
              src={MLightEffect5}
              className="absolute left-0 top-[6442px] mx-auto flex object-fill lg:hidden"
              alt=""
            />
            <div className="flex flex-col gap-[68px] lg:h-[627px] lg:flex-row lg:gap-4">
              <div className="flex w-full flex-col rounded-[20px] bg-[#FFFFFF0D] lg:w-2/5 lg:rounded-[30px]">
                <div className="mx-auto mb-[40px] mt-[60px] flex flex-col items-center justify-center lg:mb-0 lg:mt-16 lg:max-w-[275px]">
                  <p className="mb-[16px] text-center font-PPRightGrotesk-Bold text-2xl text-primary-purple">
                    FX
                  </p>
                  <p className="text-center font-inter text-[12px] font-medium leading-5 text-base-gray lg:text-[13px] lg:leading-[22px]">
                    Every mixer effect is included.
                  </p>
                </div>
                <img
                  src={FX}
                  className="mt-auto h-[262px] w-[250px] self-end object-contain lg:h-[459px] lg:w-[438px]"
                  alt=""
                />
              </div>
              <img
                src={LightEffect5}
                className="absolute hidden object-fill lg:left-0 lg:top-[6781px] lg:block"
                alt=""
              />
              <img
                src={MLightEffect2}
                className="absolute left-0 top-[7193px] mx-auto flex object-fill sm:hidden"
                alt=""
              />
              <div className="flex w-full flex-col rounded-[20px] bg-[#FFFFFF0D] pb-10 lg:w-3/5 lg:rounded-[30px] lg:px-16 lg:pb-0 lg:pt-12">
                <div className="lg:order1 order-2 mx-[35px] h-[130px] lg:mx-0 lg:h-[330px]">
                  <Lottie
                    animationData={FourthAnimation}
                    loop={true}
                    className="h-full w-full"
                  />
                </div>
                <div className="order-1 mx-auto mb-[40px] mt-[60px] flex flex-col items-center justify-center px-[35px] lg:order-2 lg:mb-0 lg:mt-14 lg:max-w-[33a0px] lg:px-0">
                  <p className="mb-[16px] text-center font-PPRightGrotesk-Bold text-2xl text-primary-purple">
                    Create and release loops
                  </p>
                  <p className="text-center font-inter text-[12px] font-medium leading-5 text-base-gray lg:max-w-[328px] lg:text-[13px] lg:leading-[22px]">
                    Drag to create a loop. Click to release the loop and play
                    the rest of the song.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Light Effects */}
          <img
            src={LightEffect2}
            className="absolute hidden object-fill object-right lg:right-0 lg:top-[7532px] lg:block"
            alt=""
          />
          <div>
            <p className="mx-auto -mb-[28px] bg-gradient-to-l from-[#DCDDF099] from-20% to-[#9DA1D74D] to-80% bg-clip-text text-center font-PPRightGrotesk-Bold text-3xl text-transparent lg:mb-12 lg:max-w-[500px] lg:text-6xl">
              Master the set for release
            </p>
          </div>

          {/* Master Set Section */}
          <div>
            <MasterSet width={width} />
          </div>

          {/* Start Free Trial Section */}
          <div>
            <StartYourFreeTrial width={width} getDensity={getDensity} />
          </div>

          <div className="hidden">
            {/* TODO: add correct gradient */}
            <p className="density-hardware-bg mx-auto text-center font-PPRightGrotesk-Bold text-3xl lg:mb-12 lg:text-6xl">
              Density Hardware
            </p>
          </div>

          <div className="flex hidden flex-col items-center overflow-hidden rounded-[20px] bg-[#FFFFFF0D] pb-[40px] pt-[60px] lg:h-[550px] lg:flex-row lg:rounded-[30px] lg:pb-[0px] lg:pl-[74px] lg:pr-0 lg:pt-0">
            <div className="mb-[40px] max-w-[440px] self-center px-[30px] text-center lg:mb-0 lg:mr-[30px] lg:px-0 lg:text-left">
              <p className="mb-[16px] font-PPRightGrotesk-Bold text-[21px] text-primary-purple lg:text-[35px] lg:leading-[39.99px]">
                Booth ready with the Density Pro hardware
              </p>
              <p className="mb-[64px] font-inter text-[12px] font-medium leading-5 text-base-gray lg:text-[14px] lg:leading-[24px]">
                The hardware is ruggedly designed for festivals and touring DJs
                to capture the data of a set. Connect it to the ethernet switch
                and the MIDI USB port of the mixer, and it’s good to go. Never
                lose a set again to clipping and recording errors.
              </p>
              <StyledAuthButton
                variant="contained"
                color="primary"
                //TODO: Add the link to the hardware page
                onClick={() => {}}
                sx={{
                  width: 220,
                  height: {
                    xs: 36,
                    md: 44,
                  },
                  backgroundColor: "#c1c5dd",
                  zIndex: 1,
                  mx: "auto",
                  marginBottom: 0,
                }}
              >
                Learn more
              </StyledAuthButton>
            </div>
          </div>

          <div className="hidden text-center">
            <p className="mb-[16px] font-PPRightGrotesk-Bold text-[24px] leading-[27.42px] text-[#C1C5DD]">
              Contact for Issues/Questions
            </p>
            <p className="font-inter text-[14px] font-medium text-[#7F849B]">
              A real human DJ can help:{" "}
              <a href="mailto:support@density.one" className="text-[#9EA1B2]">
                {" "}
                support@density.one
              </a>
            </p>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    </>
  );
};
