export const markSessionFlagIfAbsent = (key: string): boolean => {
  return markSessionFlagWithExpiry(key, Number.POSITIVE_INFINITY);
};

export const markSessionFlagWithExpiry = (
  key: string,
  validDurationSeconds: number,
): boolean => {
  const flagKey = createFlagKey(key);

  const flagAgeOpt = getFlagKeyAgeInSeconds(flagKey);
  const isReplacingFlag =
    flagAgeOpt === null || flagAgeOpt >= validDurationSeconds;

  if (isReplacingFlag) {
    sessionStorage.setItem(flagKey, `${new Date().getTime()}`);
  }

  return isReplacingFlag;
};

export const getSessionFlagAgeInSeconds = (key: string): number | null => {
  const flagKey = createFlagKey(key);
  return getFlagKeyAgeInSeconds(flagKey);
};

export const removeSessionFlag = (key: string) => {
  const flagKey = createFlagKey(key);
  sessionStorage.removeItem(flagKey);
};

const createFlagKey = (key: string): string => {
  return `__flag_${key}`;
};

const getFlagKeyAgeInSeconds = (flagKey: string): number | null => {
  const existingFlagValue = sessionStorage.getItem(flagKey);
  const existingFlagMillis = Number(existingFlagValue) || 0;
  if (existingFlagMillis === 0) {
    return null;
  }

  const nowMillis = new Date().getTime();
  return (nowMillis - existingFlagMillis) / 1000;
};
