import { useEffect, useState } from "react";
import { Box, Typography, InputLabel, Modal, Divider } from "@mui/material";

import {
  StyledAccountContent,
  StyledAuthButton,
  StyledAuthInputFields,
} from "../micro/micro.styled";

import { api } from "../../api/base";
import { useSnackBar } from "../../context/SnackBar";
import { useDispatch, useSelector } from "react-redux";
import {
  setForgotPasswordModalOpen,
  setLoginModalOpen,
  setUserDataEmail,
} from "../../redux/actions/app";

import TickCircle from "../../assets/tick-circle.svg";
import CloseCircle from "../../assets/close-circle.svg";

import LeftChevron from "../../assets/Left 20 (1).svg";

export const ForgotPasswordModal = (): JSX.Element => {
  const { showSnackBar } = useSnackBar();
  const dispatch = useDispatch();
  const forgotPasswordModalOpen = useSelector(
    (state: any) => state.app.forgotPasswordModalOpen,
  );
  const userDataEmail = useSelector((state: any) => state.app.userDataEmail);
  const [email, setEmail] = useState<string>(userDataEmail || "");
  const [emailError, setEmailError] = useState<string>("");

  const validateEmailFormat = (_email: string): boolean => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(_email.toLowerCase());
  };

  const handleClose = () => {
    dispatch(setForgotPasswordModalOpen(false));
    dispatch(setUserDataEmail(""));
  };

  const isValidEmail = (_email: string): boolean => {
    if (!_email) {
      setEmailError("This is a required field.");
      return false;
    }
    if (!validateEmailFormat(_email)) {
      setEmailError("Please enter a valid email.");
      return false;
    }
    return true;
  };

  const submitHandler = async () => {
    if (isValidEmail(email)) {
      showSnackBar(
        "Thanks! An email was sent that will ask you to click on a link to verify that you own this account.",
        "success",
        "initial",
        2000,
        false,
        "400px",
      );

      // TODO: uncomment this when the API is ready
      try {
        const response = await api.post("reset-password/request", {
          email,
        });
        if (response.status === 200) {
          handleClose();
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
    setEmailError("");
  };

  useEffect(() => {
    setEmail(userDataEmail || "");
  }, [userDataEmail]);

  const handleBack = () => {
    handleClose();
    dispatch(setLoginModalOpen(true));
  };

  return (
    <Modal
      open={forgotPasswordModalOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      sx={{
        backdropFilter: "blur(30px)",
        // '& .MuiModal-backdrop': {
        //   backgroundColor: 'rgba(0, 0, 0, 0.75)',
        // },
      }}
    >
      <Box
        gap={"20px"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        display={"flex"}
        height={"100%"}
        maxWidth={"427px"}
        width={"100%"}
        mx={"auto"}
      >
        <StyledAccountContent
          sx={{
            width: "100%",
            gap: "24px",
            backgroundColor: "#141519",
            border: "none",
            px: 0,
            pt: "36px",
            pb: "32px",
          }}
        >
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            px={"32px"}
          >
            <button
              style={{
                display: "flex",
                alignItems: "center",
                background: "transparent",
                border: "none",
                cursor: "pointer",
                position: "absolute",
              }}
              onClick={handleBack}
            >
              <img src={LeftChevron} alt="close modal" />
              <span
                style={{
                  fontSize: "12px",
                  lineHeight: "18px",
                  letterSpacing: "0.1px",
                  color: "rgba(252, 252, 252, 0.6)",
                  marginLeft: "5px",
                }}
              >
                Back
              </span>
            </button>
            <Typography variant={"h2"} sx={{ mx: "auto" }}>
              Forgot password
            </Typography>
          </Box>

          <Box sx={{ position: "relative", px: "32px", mt: "-18px" }}>
            <InputLabel
              sx={{ mb: "20px", fontSize: "11px", color: "#dcdee4" }}
              htmlFor="email"
            >
              Email
            </InputLabel>
            <Box position={"relative"}>
              <StyledAuthInputFields
                value={email}
                onChange={handleEmailChange}
                type={"email"}
                name={"email"}
                autoComplete="off"
                sx={{
                  width: "100%",
                  backgroundColor: "#100f12",
                  "& .Mui-focused": {
                    boxShadow: "none",
                    caretColor: "#575F9E !important",
                    outline: emailError
                      ? "1px solid transparent"
                      : "1px solid #575F9E",
                  },
                  "&.Mui-focused": {
                    boxShadow: "none",
                    caretColor: "#575F9E !important",
                    outline: emailError
                      ? "1px solid transparent"
                      : "1px solid #575F9E",
                  },
                  "&:focus": {
                    boxShadow: "none",
                    caretColor: "#575F9E !important",
                    outline: emailError
                      ? "1px solid transparent"
                      : "1px solid #575F9E",
                  },
                }}
                error={emailError !== ""}
              />
              {email && !emailError && (
                <img
                  style={{
                    height: "16px",
                    width: "16px",
                    objectFit: "contain",
                    position: "absolute",
                    top: "50%",
                    right: "16px",
                    transform: "translateY(-50%)",
                  }}
                  src={TickCircle}
                  alt=""
                />
              )}
              {emailError && (
                <img
                  style={{
                    height: "16px",
                    width: "16px",
                    objectFit: "contain",
                    position: "absolute",
                    top: "50%",
                    right: "16px",
                    transform: "translateY(-50%)",
                  }}
                  src={CloseCircle}
                  alt=""
                />
              )}
            </Box>
            {emailError && (
              <Typography fontSize={12} color={"error"} variant={"body1"}>
                {emailError}
              </Typography>
            )}
          </Box>

          <Divider
            sx={{
              width: "100%",
              mt: "-8px",
            }}
          />
          <Box display={"flex"} justifyContent={"center"} px={"32px"}>
            <StyledAuthButton
              variant={"contained"}
              sx={{
                width: "100%",
                mx: "auto",
                marginBottom: 0,
                height: "36px",
              }}
              onClick={submitHandler}
            >
              Request a reset link
            </StyledAuthButton>
          </Box>
        </StyledAccountContent>
      </Box>
    </Modal>
  );
};
