export enum DeviceType {
  Mobile = "mobile",
  Tablet = "tablet",
  Desktop = "desktop",
}

export enum OS {
  Android = "android",
  IOS = "ios",
  Windows = "windows",
  MacOS = "macos",
  Linux = "linux",
  Unknown = "unknown",
}
