import { useNavigate } from "react-router-dom";
import { Box, Typography, Modal, Divider } from "@mui/material";

import {
  StyledAccountContent,
  StyledAuthButton,
} from "../../components/micro/micro.styled";
import { useDispatch } from "react-redux";
import { setLoginModalOpen } from "../../redux/actions/app";

export interface ResetPasswordModalProps {
  open: boolean;
  hasError: boolean;
}

export const ResetPasswordModal = ({
  open,
  hasError,
}: ResetPasswordModalProps): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const backToLogin = () => {
    navigate("/");
    dispatch(setLoginModalOpen(true));
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      sx={{
        backdropFilter: "blur(3px)",
        "& .MuiModal-backdrop": {
          backgroundColor: "rgba(0, 0, 0, 0.75)",
        },
      }}
    >
      <Box
        gap={"20px"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        display={"flex"}
        height={"100%"}
        maxWidth={"420px"}
        width={"100%"}
        mx={"auto"}
      >
        <StyledAccountContent
          sx={{ width: "100%", gap: "16px", backgroundColor: "#141519" }}
        >
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography variant={"h2"}>
              {!hasError ? "Success!" : "Oops!"}
            </Typography>
          </Box>
          <Typography
            alignItems={"center"}
            display={"flex"}
            justifyContent={""}
            variant="body1"
            fontSize={12}
          >
            {!hasError
              ? "You have successfully changed your password!"
              : "Something went wrong, please try again!"}
          </Typography>
          <Divider
            sx={{
              width: "100%",
            }}
          />
          <Box display={"flex"} justifyContent={"center"}>
            <StyledAuthButton
              variant={"contained"}
              sx={{
                width: "100%",
                mx: "auto",
                marginBottom: "7px",
                marginTop: "10px",
              }}
              onClick={backToLogin}
            >
              Go back to login
            </StyledAuthButton>
          </Box>
        </StyledAccountContent>
      </Box>
    </Modal>
  );
};
