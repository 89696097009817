import { types } from "../../actions/app";

const initialState = {
  sessionId: "",
  paymentDetailsChanged: false,
  loginModalOpen: false,
  forgotPasswordModalOpen: false,
  userDataEmail: "",
};

export const appReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.SESSION_CREATED:
      return {
        ...state,
        sessionId: action.payload.sessionId,
      };
    case types.PAYMENT_DETAILS_CHANGED:
      return {
        ...state,
        paymentDetailsChanged: action.payload.paymentDetailsChanged,
      };
    case types.LOGIN_MODAL_OPEN:
      return {
        ...state,
        loginModalOpen: action.payload.loginModalOpen,
      };
    case types.SET_USER_DATA_EMAIL:
      return {
        ...state,
        userDataEmail: action.payload.email,
      };
    case types.SET_FORGOT_PASSWORD_MODAL_OPEN:
      return {
        ...state,
        forgotPasswordModalOpen: action.payload.forgotPasswordModalOpen,
      };
    default:
      return state;
  }
};
