import { Link } from "@mui/material";
import { InfoPanelProps } from "./types";
import { useNavigate } from "react-router-dom";

export function InfoPanel({
  data,
  children,
  className = "",
}: {
  data: InfoPanelProps;
  children: React.ReactNode;
  className?: string;
}) {
  const { title, subtitle, description, heading, link, descriptionLink } = data;

  const navigate = useNavigate();
  return (
    <div
      className={`flex flex-col items-center justify-center text-center text-primary-purple ${className}`}
    >
      {title && (
        <div className="mb-[4.6875rem] font-['PPRightGrotesk-Bold'] text-[34px] font-black leading-[38.85px]">
          {title}
          {link?.url && (
            <div className="mb-10 text-center">
              <Link
                onClick={() => {
                  navigate(link.url);
                  window.scrollTo(0, 0);
                }}
                variant="body2"
                className="cursor-pointer text-left font-inter text-base text-base font-medium leading-6 leading-6 tracking-wider !text-primary-purple"
                sx={{
                  fontFamily: "Inter !important",
                  fontSize: "14px !important",
                  fontWeight: 500,
                  lineHeight: "24px",
                  letterSpacing: "0.0125rem",
                  textAlign: "left !important",
                }}
                target="_blank"
              >
                {link.title}
              </Link>
            </div>
          )}
        </div>
      )}
      <div className="md:py-8 md:pl-8">{children}</div>
      {heading && (
        <div className="text-center font-['PPRightGrotesk-Bold'] text-[24px] font-black leading-[27.42px]">
          {heading}
        </div>
      )}
      {subtitle && (
        <div className="max-w-[30ch] font-['Inter'] text-[12px] font-medium leading-[18px] tracking-[0.1px] !text-base-gray ">
          {subtitle}
          {descriptionLink?.url && (
            <div className="mb-10 text-center">
              <Link
                onClick={() => {
                  if (descriptionLink.action) {
                    descriptionLink.action();
                  } else {
                    navigate(descriptionLink.url);
                    window.scrollTo(0, 0);
                  }
                }}
                variant="body2"
                className="cursor-pointer text-left font-inter text-base text-base font-medium leading-6 leading-6 tracking-wider !text-primary-purple"
                sx={{
                  fontFamily: "Inter !important",
                  fontSize: "14px !important",
                  fontWeight: 500,
                  lineHeight: "24px",
                  letterSpacing: "0.0125rem",
                  textAlign: "left !important",
                }}
                target="_blank"
              >
                {descriptionLink.title}
              </Link>
            </div>
          )}
        </div>
      )}
      {description && (
        <>
          <div className="pt-4 font-['Inter'] text-[12px] font-medium leading-[18px] tracking-[0.1px] !text-base-gray">
            {description}
          </div>
        </>
      )}
    </div>
  );
}
