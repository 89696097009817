import React, { useEffect, useState } from "react";
import { api } from "../../api/base";
import { AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  InputLabel,
  TextField,
  Divider,
} from "@mui/material";

import { useUserContext } from "../../context/UserContext";
import {
  StyledAccountContent,
  StyledDashContainer,
} from "../../components/micro/micro.styled";
import { Header } from "../../components/Header";

import { ReactComponent as CheckIcon } from "../../assets/check-icon.svg";
import { useDispatch } from "react-redux";
import { paymentDetailsChanged } from "../../redux/actions/app";
import { useWindowDimensions } from "../../utils/hooks/windowDimensionsHook";

export const PaymentMethodPage = (): JSX.Element => {
  const { width } = useWindowDimensions();
  const [email, setEmail] = useState<string>("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useUserContext();
  const [iframeURL, setIframeURL] = useState<string>("");
  const [step, setStep] = useState(0);

  const getPaymentMethodPage = async (): Promise<void> => {
    try {
      const { data }: AxiosResponse<any, any> = await api.post(
        "chargebee/payment-update",
      );
      setIframeURL(data.url);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getPaymentMethodPage();
  }, []);

  return (
    <StyledDashContainer>
      <Header absolute={width > 769} />
      <Box
        className={"payment-method-container"}
        gap={"20px"}
        alignItems={"center"}
        flexDirection={"column"}
        display={"flex"}
        height={"100%"}
        width={"100%"}
        maxWidth={"500px"}
        mx={"auto"}
      >
        {step === 1 && (
          <StyledAccountContent sx={{ alignItems: "center", width: "100%" }}>
            <CheckIcon />
            <Typography textAlign={"center"} variant={"h5"}>
              You've changed your payment details.
            </Typography>
            <Button variant={"contained"} onClick={() => navigate("/account")}>
              Back
            </Button>
          </StyledAccountContent>
        )}
        {step === 0 && (
          <StyledAccountContent
            sx={{
              gap: 0,
              backgroundColor: "transparent",
              border: "none",
              boxShadow: "none",
              width: "100%",
              mt: {
                md: "80px",
              },
            }}
          >
            <Typography
              variant={"h5"}
              fontSize={20}
              lineHeight={"24px"}
              fontWeight={500}
              letterSpacing={"0.15px"}
              textAlign={"center"}
              mb={"16px"}
            >
              Edit payment method
            </Typography>
            <Box width={"100%"} height={"100%"}>
              {iframeURL !== "" && (
                <iframe
                  style={{
                    width: "100%",
                    border: "none",
                  }}
                  className="payment-method-iframe"
                  src={iframeURL}
                  title={"chargebee-change-payment"}
                />
              )}
            </Box>
            <Divider
              sx={{
                my: "24px",
              }}
            />
            <Box display={"flex"} justifyContent={"space-between"} gap={2}>
              <Button
                fullWidth
                sx={{
                  backgroundColor: "#37383c",
                  color: "#F3F3F5",
                  borderColor: "#37383c",
                  "&:hover": {
                    borderColor: "#37383c",
                  },
                }}
                variant={"outlined"}
                onClick={() => navigate("/account")}
              >
                Cancel
              </Button>
              <Button
                fullWidth
                variant={"contained"}
                onClick={() => {
                  dispatch(paymentDetailsChanged(true));
                  navigate("/account");
                  setIframeURL("");
                }}
                sx={{
                  "&:disabled": {
                    color: "rgba(2, 2, 3, 0.5)",
                  },
                }}
              >
                Save
              </Button>
            </Box>
          </StyledAccountContent>
        )}
      </Box>
    </StyledDashContainer>
  );
};
