import { useEffect } from "react";
import uuid from "react-uuid";
import {
  Route,
  Routes,
  Navigate,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import { Provider } from "react-redux";
import { CookiesProvider } from "react-cookie";

import { store } from "./redux/store";
import { ColorModeProvider } from "./context/ColorMode";
import { SnackBarProvider } from "./context/SnackBar";
import { UserProvider } from "./context/UserContext";

import { AppContainer } from "./components/micro/AppContainer";

import { LoginPage } from "./pages/Login";
import { RegisterPage } from "./pages/Register";
import { ResetPasswordPage } from "./pages/ResetPassword";
import { ProfilePage } from "./pages/Profile";
import { CanceledPage } from "./pages/CanceledPlan";
import { PaymentMethodPage } from "./pages/PaymentMethod";
import { LandingPage } from "./pages/LandingPage";
import { WaitlistPage } from "./pages/Waitlist";
import { DawLoginPage } from "./pages/Daw/Login";
import { ProtectedRoute } from "./utils/routes/ProtectedRoute";
import { PublicRoute } from "./utils/routes/PublicRoute";

import "./main.css";
import { NotFoundPage } from "./pages/NotFound";
import { PlansPage } from "./pages/Plans";
import { Landing } from "./pages/Landing";
import { UpgradeSuccessPage } from "./pages/UpgradeSuccess";
import { UniversalDownload } from "./pages/Download/Universal";
import { DownloadPage } from "./pages/Download";
import { Reminder } from "./pages/EmailReminder";
import { HardwareLandingPage } from "./pages/HardwareLandingPage";
import { Checkout } from "./pages/Checkout";
import { RecSetupPage } from "./pages/RecSetup";
import { PricingPage } from "./pages/Pricing";
import { tryGetRedirection } from "./utils/redirection";
import { fetchAndRememberUserSettings } from "./storage/user.settings";
import { rememberUtmParams } from "./storage/utm.params";
import { RegisterPageRedirectObsolete } from "./pages/Register/redirect.obsolete";
import { rememberReferralInfo } from "./storage/referral.info";

export function App() {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const redirectionOpt = tryGetRedirection(location, searchParams);

  useEffect(() => {
    fetchAndRememberUserSettings();
  });

  useEffect(() => {
    if (!localStorage.getItem("user_id")) {
      localStorage.setItem("user_id", uuid());
    }
  });

  useEffect(() => {
    if (redirectionOpt) {
      // Don't bother, we are about to navigate and change search params.
      return;
    }

    rememberUtmParams(searchParams);
    rememberReferralInfo(searchParams);
  }, [redirectionOpt, searchParams]);

  if (redirectionOpt) {
    return <Navigate to={redirectionOpt} replace={true} />;
  }

  return (
    <CookiesProvider>
      <ColorModeProvider>
        <UserProvider>
          <SnackBarProvider>
            <Provider store={store}>
              <Routes>
                <Route
                  path={"/"}
                  element={
                    <PublicRoute>
                      <AppContainer />
                    </PublicRoute>
                  }
                >
                  <Route index element={<Landing />} />
                  <Route path={"landing"} element={<LandingPage />} />
                  <Route path={"hardware"} element={<HardwareLandingPage />} />
                  <Route path={"rec-setup"} element={<RecSetupPage />} />
                  <Route path={"pricing"} element={<PricingPage />} />
                  <Route path={"checkout"} element={<Checkout />} />
                  <Route path={"login"} element={<LoginPage />} />
                  <Route
                    path={"onboarding/:stepNum/density38"}
                    element={<RegisterPageRedirectObsolete />}
                  />
                  <Route
                    path={"onboarding/:stepNum?/:refId?"}
                    element={<RegisterPage />}
                  />
                  <Route
                    path={"reset-password"}
                    element={<ResetPasswordPage />}
                  />
                  <Route path={"waitlist"} element={<WaitlistPage />} />
                </Route>
                <Route
                  path={"/account"}
                  element={
                    <ProtectedRoute>
                      <AppContainer />
                    </ProtectedRoute>
                  }
                >
                  <Route path={"hardware"} element={<HardwareLandingPage />} />
                  <Route path={"checkout"} element={<Checkout />} />

                  <Route path={"download"} element={<DownloadPage />} />
                  <Route path={"plans"} element={<PlansPage />} />
                  <Route index element={<ProfilePage />} />
                  <Route path={"canceled-plan"} element={<CanceledPage />} />
                  <Route
                    path={"payment-method"}
                    element={<PaymentMethodPage />}
                  />
                  <Route
                    path={"upgrade-success"}
                    element={<UpgradeSuccessPage />}
                  />
                </Route>
                <Route path="daw">
                  <Route path="login" element={<DawLoginPage />} />
                </Route>
                <Route path="download" element={<UniversalDownload />} />
                <Route path="download-intel" element={<UniversalDownload />} />
                <Route path="download-arm" element={<UniversalDownload />} />
                <Route path="*" element={<NotFoundPage />} />{" "}
                <Route path="reminder" element={<Reminder />} />
                {/* Global catch-all route */}
              </Routes>
            </Provider>
          </SnackBarProvider>
        </UserProvider>
      </ColorModeProvider>
    </CookiesProvider>
  );
}
