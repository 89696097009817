import { Navigate, useParams, useSearchParams } from "react-router-dom";

// There used to be old behavior where a lack of referrer was replaced
// by a default "density38" ref ID ("Density" referrer name). This has
// been removed, but there are still links in the wild that use it.
//
// This re-routes such links to strip the referral off entirely.
export const RegisterPageRedirectObsolete = (): JSX.Element => {
  const [searchParams] = useSearchParams();
  let { stepNum } = useParams();

  const getUpdatedStepNum = () => {
    if (stepNum === "5" || stepNum === "6") {
      return stepNum;
    } else {
      return "1";
    }
  };
  const getUpdatedPath = () => {
    return `/onboarding/${getUpdatedStepNum()}`;
  };
  const getUpdatedSearchParams = () => {
    const updatedSearchParams = new URLSearchParams(searchParams);
    updatedSearchParams.delete("referrer");

    return updatedSearchParams;
  };

  return (
    <Navigate
      to={{
        pathname: getUpdatedPath(),
        search: getUpdatedSearchParams().toString(),
      }}
      replace={true}
    />
  );
};
