import { useEffect, useState } from "react";

// TODO: Factor these constants globally.
const DAW_VERSION_DEFAULT: string = "0.1.220";
const DAW_RELEASE_URL: string =
  "https://release.density.one/Density-Installer-Universal-Latest.dmg";
const DAW_RELEASE_BUCKET_URL: string =
  "https://storage.googleapis.com/release.density.one/Density-Installer-Universal-Latest.dmg";

const tryQueryDawVersion = async (): Promise<string | null> => {
  try {
    const response = await fetch(DAW_RELEASE_BUCKET_URL, { method: "HEAD" });
    const densityVersionHeader = response.headers.get(
      "x-goog-meta-density-version",
    );

    return densityVersionHeader ? densityVersionHeader : null;
  } catch (error) {
    console.warn("Failed to query daw version:", error);
    return null;
  }
};

let cachedDawVersion: string = "";
let cachedFetchDawVersionInternal: Promise<string>;
const fetchCachedDawVersion = async (): Promise<string> => {
  const fetchCachedDawVersionInternal = async (): Promise<string> => {
    const dawVersionOpt = await tryQueryDawVersion();

    // TODO: Add retries.
    cachedDawVersion = dawVersionOpt || DAW_VERSION_DEFAULT;
    return cachedDawVersion;
  };

  if (!cachedFetchDawVersionInternal) {
    cachedFetchDawVersionInternal = fetchCachedDawVersionInternal();
  }

  return cachedFetchDawVersionInternal;
};

export const DawVersion = (): JSX.Element => {
  const [dawVersion, setDawVersion] = useState(cachedDawVersion);
  const displayDawVersion = dawVersion || DAW_VERSION_DEFAULT;

  const fetchDawVersion = async () => {
    const latestDawVersion = await fetchCachedDawVersion();
    setDawVersion(latestDawVersion);
  };

  useEffect(() => {
    if (!dawVersion) {
      fetchDawVersion();
    }
  }, []);

  return <>{`${displayDawVersion}`}</>;
};
