import { Box, Link } from "@mui/material";

import { StyledLandingPageFooter } from "../../pages/LandingPage/landing.styled";

import TiktokIcon from "../../assets/tiktok.svg";
import InstagramIcon from "../../assets/instagram.svg";
import YoutubeIcon from "../../assets/youtube.svg";
import LandingLogo from "../../assets/Density Logo cropped.svg";
import { useEffect, useState } from "react";

export const Footer = () => {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <StyledLandingPageFooter>
      <Box>
        <Box className="footer-content">
          <img
            src={LandingLogo}
            alt="logo"
            style={{
              width: "102px",
              height: "32px",
              objectFit: "contain",
              order: 1,
            }}
          />
          <Box className="footer-text-wrapper">
            {!loading && (
              <p className="footer-text">
                {new Date().getFullYear()} © DENSITY. ALL RIGHTS RESERVED.
                CONTACT US:{" "}
                <a href="mailto:contact@density.com">CONTACT@DENSITY.ONE</a>
              </p>
            )}
            <p className="footer-text">
              Tempo and beat tracking engine: AUFTAKT V4. Time stretching
              engine: Élastique Pro V3. Both by zplane.development.
            </p>
          </Box>
          <Box
            className="footer-social-wrapper"
            sx={{
              display: "flex",
              gap: "30px",
            }}
          >
            {/* <img
          style={{
            height: "18px",
            objectFit: "contain",
          }}
          src={TiktokIcon}
          alt=""
        /> */}
            <a
              href="https://www.tiktok.com/@densityone"
              target="_blank"
              rel="noopener noreferrer"
              style={{ width: "100%" }}
            >
              <img
                style={{
                  height: "18px",
                  objectFit: "contain",
                }}
                src={TiktokIcon}
                alt="Density Tiktok"
              />
            </a>
            <a
              href="https://www.instagram.com/density.one/"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                width: "100%",
              }}
            >
              <img
                style={{
                  height: "18px",
                  objectFit: "contain",
                }}
                src={InstagramIcon}
                alt="Density Instagram"
              />
            </a>
            <a
              href="https://www.youtube.com/@densityone"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                width: "100%",
              }}
            >
              <img
                style={{
                  height: "18px",
                  objectFit: "contain",
                }}
                src={YoutubeIcon}
                alt=""
              />
            </a>
          </Box>
        </Box>
        <Box className="footer-content-links">
          <Link
            href="https://app.termly.io/policy-viewer/policy.html?policyUUID=03698bd6-c7ca-43bb-98ea-85ddf4d59ac2"
            underline="hover"
            color="inherit"
            sx={{ margin: "0 10px", color: "#7F849B" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </Link>
          <Link
            href="https://app.termly.io/policy-viewer/policy.html?policyUUID=7914c73d-5884-428c-8e4f-ad3a3505eb5d"
            underline="hover"
            color="inherit"
            sx={{ margin: "0 10px", color: "#7F849B" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            Cookie Policy
          </Link>
          <Link
            href="https://app.termly.io/policy-viewer/policy.html?policyUUID=7ff47477-32d1-4887-b7a7-30cacce61e26"
            underline="hover"
            color="inherit"
            sx={{ margin: "0 10px", color: "#7F849B" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms and Conditions
          </Link>
        </Box>
      </Box>
    </StyledLandingPageFooter>
  );
};
