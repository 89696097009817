import React, { useEffect, useRef, useState } from "react";

import { Button, Divider, Menu } from "@mui/material";
import { StyledHeaderContainer } from "./Header.styled";

import { Link, useNavigate } from "react-router-dom";
import DensityLogo from "../../assets/Density app logo.svg";
import { StyledMenuItem } from "../micro/micro.styled";
import { AxiosResponse } from "axios";
import { api } from "../../api/base";

import CaretDown from "../../assets/CaretDown.svg";
import { useUserContext } from "../../context/UserContext";
import { useDispatch } from "react-redux";
import { setLoginModalOpen } from "../../redux/actions/app";
import { analyticsEvent } from "../../redux/actions/analytics";
import { useWindowDimensions } from "../../utils/hooks/windowDimensionsHook";

export const Header = (props: any): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, setUser, clearUser } = useUserContext();
  const elementRef = useRef<any>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [hardwareAnchorEl, setHardwareAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const [menuWidth, setMenuWidth] = useState(0);
  const open = Boolean(anchorEl);
  const openH = Boolean(hardwareAnchorEl);
  const { width } = useWindowDimensions();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickH = (event: React.MouseEvent<HTMLElement>) => {
    setHardwareAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseHMenuItem = () => {
    setHardwareAnchorEl(null);
  };

  const handleHelpClick = () => {
    handleClose();
    dispatch(analyticsEvent("EVENT", "Support Button Clicked", {}));
    window.open("https://support.density.one/", "_blank");
  };

  const handleManageAccountClick = () => {
    handleClose();
    navigate("/account");
  };

  const handleHardwareClick = () => {
    handleClose();
    navigate("/account/hardware");
  };

  const handleDownload = () => {
    navigate("/account/download");
  };

  const handleAboutRec1Click = () => {
    handleCloseHMenuItem();
    navigate("/hardware");
  };

  const handleRecSetupClick = () => {
    handleCloseHMenuItem();
    navigate("/rec-setup");
  };

  const handleLogout = (): void => {
    const preserveKey = "TERMLY_API_CACHE";
    const preserveValue = localStorage.getItem(preserveKey);
    localStorage.clear();
    clearUser();
    if (preserveValue !== null) {
      localStorage.setItem(preserveKey, preserveValue);
    }

    navigate("/");
    dispatch(setLoginModalOpen(true));
    dispatch(
      analyticsEvent("EVENT", "Logged Out", {
        plan: "free",
      }),
    );
  };

  const getProfile = async (): Promise<void> => {
    try {
      const response: AxiosResponse<any, any> = await api.get("auth/profile");
      const { data } = response;
      setUser(data.user);
    } catch (err: any) {
      if (err.response?.status === 401 || err.response?.status === 403) {
        try {
          const refresh_token = localStorage.getItem("refresh_token");
          const tokens = await api.post("/auth/refresh", {
            refresh_token,
          });
          localStorage.setItem("access_token", tokens.data.access_token);
          localStorage.setItem("refresh_token", tokens.data.refresh_token);
          const { data }: AxiosResponse<any, any> =
            await api.get("auth/profile");
          setUser(data.user);
        } catch (err: any) {
          const preserveKey = "TERMLY_API_CACHE";
          const preserveValue = localStorage.getItem(preserveKey);
          localStorage.clear();

          if (preserveValue !== null) {
            localStorage.setItem(preserveKey, preserveValue);
          }

          const currentPath = window.location.pathname;
          if (currentPath === "/daw/login") {
            window.location.reload();
          } else {
            navigate("/");
          }
        }
      }
    }
  };

  useEffect(() => {
    if (Object.keys(user).length === 0) {
      getProfile();
    }
    if (elementRef.current && user) {
      setMenuWidth(elementRef.current?.getBoundingClientRect()?.width);
    }
  }, [user]);

  return (
    <StyledHeaderContainer
      sx={{
        position: props.absolute ? "absolute" : "relative",
        width: "100%",
        zIndex: 100,
      }}
      className="!flex !flex-row !items-center !justify-between"
    >
      <div style={{ cursor: "pointer" }} onClick={() => navigate("/account")}>
        <img
          src={DensityLogo}
          alt={"Density"}
          className="!m-0 !max-h-[36px] w-[117px] cursor-pointer"
        />
      </div>
      <div className="hidden md:flex">
        <Link
          to="/account/download"
          className="mr-[30px] font-ibmPlexMono text-[13px] font-semibold leading-[16px] tracking-[1.25px] text-[#FCFCFC99] hover:text-[#FCFCFC]"
        >
          DOWNLOAD
        </Link>

        <h3
          className="mr-[30px] cursor-pointer font-ibmPlexMono text-[13px] font-semibold leading-[16px] tracking-[1.25px] text-[#FCFCFC99] hover:text-[#FCFCFC]"
          onClick={handleClickH}
        >
          HARDWARE
        </h3>
        <Menu
          anchorEl={hardwareAnchorEl}
          id="hardware-menu"
          open={openH}
          onClose={handleCloseHMenuItem}
          onClick={handleCloseHMenuItem}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 3,
              gap: "16px",
              backgroundColor: "#141519",
              boxShadow: "0px 2px 1px 0px rgba(0, 0, 0, 0.25)",
              width: menuWidth,
              minWidth: "180px",
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                width: "100%",
                height: 2,
                bgcolor: "#424FB8",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <StyledMenuItem
            sx={{
              mt: 1,
            }}
            onClick={handleAboutRec1Click}
          >
            About REC-1
          </StyledMenuItem>
          <StyledMenuItem
            sx={{
              mt: 1,
            }}
            onClick={handleRecSetupClick}
          >
            rec-1 setup
          </StyledMenuItem>
        </Menu>
        <Link
          to="/pricing"
          className="mr-[30px] font-ibmPlexMono text-[13px] font-semibold leading-[16px] tracking-[1.25px] text-[#FCFCFC99] hover:text-[#FCFCFC]"
        >
          PRICING
        </Link>
        <p
          onClick={handleHelpClick}
          className="cursor-pointer font-ibmPlexMono text-[13px] font-semibold leading-[16px] tracking-[1.25px] text-[#FCFCFC99] hover:text-[#FCFCFC]"
        >
          SUPPORT
        </p>
      </div>
      <Button
        ref={elementRef}
        onClick={handleClick}
        variant={"text"}
        className="!m-0 !flex items-center "
      >
        {width > 769 ? user?.name || user?.email : "Account"}
        <img
          className="expand-icon mb-0"
          style={{
            marginLeft: "13px",
            height: 20,
            width: 20,
            objectFit: "contain",
          }}
          src={CaretDown}
          alt="expand"
        />
      </Button>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 3,
            gap: "16px",
            backgroundColor: "#141519",
            boxShadow: "0px 2px 1px 0px rgba(0, 0, 0, 0.25)",
            width: menuWidth,
            minWidth: "180px",
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              width: "100%",
              height: 2,
              bgcolor: "#424FB8",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <StyledMenuItem
          sx={{
            mt: 1,
          }}
          onClick={handleManageAccountClick}
        >
          Manage account
        </StyledMenuItem>
        <StyledMenuItem
          sx={{
            mt: 1,
          }}
          onClick={handleHardwareClick}
        >
          Hardware
        </StyledMenuItem>
        <StyledMenuItem className="md:!hidden" onClick={handleHelpClick}>
          Support
        </StyledMenuItem>
        <StyledMenuItem className="md:!hidden" onClick={handleDownload}>
          Download
        </StyledMenuItem>
        <Divider
          sx={{
            borderColor: "#090b0d",
            height: 2,
            background: "#1f2125",
          }}
        />
        <StyledMenuItem onClick={handleLogout}>Log out</StyledMenuItem>
      </Menu>
    </StyledHeaderContainer>
  );
};
