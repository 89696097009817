// TODO: Eventually the key can be an enum, so the set of local storage
// operations is closed. This will make clearing subsets of storage easier.

export const storeLocal = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

export const loadLocal = (key: string): string | null => {
  return localStorage.getItem(key);
};

export const clearLocal = (key: string) => {
  return localStorage.removeItem(key);
};

export const storeLocalJSON = (key: string, value: any) => {
  const valueJSON = JSON.stringify(value);
  storeLocal(key, valueJSON);
};

export const loadLocalJSON = (key: string): any | null => {
  const value = loadLocal(key);
  if (!value) {
    return null;
  }

  try {
    return JSON.parse(value);
  } catch (err) {
    console.warn(`Invalid local JSON for ${key}`, err);
    return null;
  }
};
