import React, { useEffect } from "react";
import { LandingNavbar } from "../../../components/LandingNavbar";
import { useWindowDimensions } from "../../../utils/hooks/windowDimensionsHook";
import { Header } from "../../../components/Header";
import WinLaptop from "../../../assets/win-laptop.png";
import { useUserContext } from "../../../context/UserContext";
import {
  StyledAuthButton,
  StyledAuthContainer,
} from "../../../components/micro/micro.styled";
import { setLoginModalOpen } from "../../../redux/actions/app";
import { useDispatch } from "react-redux";
import { api } from "../../../api/base";
import { analyticsEvent } from "../../../redux/actions/analytics";
import { GTMEvent, gtmTriggerEvent } from "../../../utils/gtm";

export const DawLoginPage: React.FC = () => {
  const { width } = useWindowDimensions();
  const { user, fetchOtp, otp } = useUserContext();
  const dispatch = useDispatch();
  const access_token = localStorage.getItem("access_token");

  useEffect(() => {
    dispatch(
      analyticsEvent("VISIT", "Web Magic login - Visited", {
        page: "Web Magic login",
      }),
    );
  }, []);

  useEffect(() => {
    if (!otp) {
      fetchOtp();
    }
  }, [otp, fetchOtp]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (user?.email && access_token) {
        fetchOtp();
      }
    }, 30000);

    return () => clearInterval(interval);
  }, [fetchOtp]);

  useEffect(() => {
    if (!user?.email) {
      dispatch(setLoginModalOpen(true));
    }
  }, [user, dispatch]);

  const handleAutoLogin = () => {
    if (user?.email && otp) {
      dispatch(
        analyticsEvent("INPUT", "Web Magic login - Clicked", {
          page: "Web Magic login",
        }),
      );
      gtmTriggerEvent(GTMEvent.MagicLogin);

      const email = encodeURIComponent(user.email);
      const otpEncoded = encodeURIComponent(otp);
      const deeplink = `density://login?email=${email}&otp=${otpEncoded}`;

      window.open(deeplink, "_blank");
    } else {
      dispatch(setLoginModalOpen(true));
    }
  };

  return (
    <StyledAuthContainer>
      {access_token ? (
        <Header />
      ) : (
        <LandingNavbar visible={false} ww={width} getDensity={() => {}} />
      )}
      <div className="flex min-h-screen items-center justify-center overflow-hidden">
        <div className="flex max-w-full flex-col  p-[30px] text-center lg:p-[0]">
          <img
            className="mx-auto mb-[35px] w-[200px] lg:w-[292px]"
            src={WinLaptop}
            alt=""
          />
          <p className="mb-[32px] px-[15] text-center text-[20px] font-medium leading-[24px] tracking-[0.15px] text-[#FCFCFC]">
            {`${user?.email ? `Hi ${user.email}, click to automatically login` : "Log in to get started"} `}
          </p>
          <StyledAuthButton
            onClick={handleAutoLogin}
            variant={"contained"}
            className="m-0"
          >
            {`${user?.email ? `Auto-Login to Density` : "Log in "} `}
          </StyledAuthButton>
          <p className="font-normal leading-[24px] tracking-[0.15px] text-[#DCDEE4] lg:text-[16px]">
            Need help from a human DJ?
            <a className="text-[#99A0C5]" href="mailto:support@density.one">
              support@density.one
            </a>
          </p>
        </div>
      </div>
    </StyledAuthContainer>
  );
};
