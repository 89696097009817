import React, { ReactNode } from "react";
import { StyledContentContainer } from "./micro.styled";

export interface Props {
  children: string | JSX.Element | JSX.Element[];
}

export const Content = ({ children }: Props): JSX.Element => {
  return <StyledContentContainer>{children}</StyledContentContainer>;
};
