import React, { SyntheticEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  Modal,
  Divider,
  Collapse,
  Radio,
  TextareaAutosize,
} from "@mui/material";

import {
  StyledAccountContent,
  StyledCancelButton,
  StyledExpectationButton,
} from "../../components/micro/micro.styled";

import CloseModalIcon from "../../assets/CloseModalIcon.svg";
import HeartIcon from "../../assets/HeartIcon.svg";
import MoneyIcon from "../../assets/MoneyIcon.svg";
import SadEmojiIcon from "../../assets/SadEmojiIcon.svg";

import { api } from "../../api/base";
import { AxiosResponse } from "axios";
import { useDispatch } from "react-redux";
import { analyticsEvent } from "../../redux/actions/analytics";
import { useSnackBar } from "../../context/SnackBar";

export interface CancelPlanModalProps {
  open: boolean;
  handleClose: () => void;
  planId?: string;
  openSuccessModal: () => void;
  lowerMyRate: () => void;
  subscription: any;
}

export const CancelPlanModal = ({
  open,
  handleClose,
  planId,
  openSuccessModal,
  lowerMyRate,
  subscription,
}: CancelPlanModalProps): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showSnackBar } = useSnackBar();
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [cancelReason, setCancelReason] = useState<string>("");
  const [cancelSubReason, setCancelSubReason] = useState<string>("");
  const [additionalNote, setAdditionalNote] = useState<string>("");

  const [completeCancelReason, setCompleteCancelReason] = useState<string>("");

  const handleCancelReason = (reason: typeof cancelReason) => {
    dispatch(
      analyticsEvent("EVENT", "Cancel-2.1: Reason Selected", {
        reason,
        cancelSubReason,
      }),
    );
    if (reason === "expectations") {
      setCollapsed(true);
      setCancelReason(reason);
    } else {
      setCancelReason(reason);
      setCollapsed(false);
    }
  };

  const handleCancelSubReason = (
    e: SyntheticEvent,
    subReason: typeof cancelSubReason,
  ) => {
    e.stopPropagation();
    dispatch(
      analyticsEvent("EVENT", "Cancel-2.2: Sub-Reason Selected", {
        reason: cancelReason,
        subReason,
      }),
    );
    setCancelSubReason(subReason);
  };

  const handleSetAdditionalNote = (
    e: SyntheticEvent,
    note: typeof additionalNote,
  ) => {
    e.stopPropagation();
    setAdditionalNote(note);
  };

  useEffect(() => {
    setCancelSubReason("");
    setAdditionalNote("");
  }, [cancelReason]);

  useEffect(() => {
    setAdditionalNote("");
  }, [cancelSubReason]);

  useEffect(() => {
    setCancelReason("");
    setCancelSubReason("");
    setAdditionalNote("");
    setCollapsed(false);
  }, [open]);

  useEffect(() => {
    if (cancelSubReason) {
      setCompleteCancelReason(
        `${cancelReason} / ${cancelSubReason}: ${additionalNote}`,
      );
    } else if (cancelReason === "no use") {
      setCompleteCancelReason(`${cancelReason}: ${additionalNote}`);
    } else {
      setCompleteCancelReason(cancelReason);
    }
  }, [cancelReason, cancelSubReason, additionalNote]);

  const cancelSubscription = async (): Promise<void> => {
    try {
      const { data }: AxiosResponse<any, any> = await api.post(
        "chargebee/cancel-subscription",
        {
          itemId: planId,
          reason: completeCancelReason,
        },
      );
      dispatch(
        analyticsEvent("EVENT", "Cancel-3: Subscription Canceled", {
          reason: completeCancelReason,
          subReason: cancelSubReason,
          note: additionalNote,
        }),
      );
      openSuccessModal();
    } catch (err) {
      console.log(err);

      showSnackBar("An error occurred while cancelling subscription", "error");
    }
  };

  const rateLowered = subscription.unit_price === 750;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      sx={{
        minWidth: "350px",
        backdropFilter: "blur(3px)",
        "& .MuiModal-backdrop": {
          backgroundColor: "rgba(0, 0, 0, 0.75)",
        },
      }}
    >
      <Box
        gap={"20px"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        display={"flex"}
        height={"100%"}
        width={"100%"}
      >
        <StyledAccountContent
          sx={{ gap: "16px", backgroundColor: "#141519", overflowY: "auto" }}
        >
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            zIndex={0}
          >
            <Typography variant={"h2"}>Why are you canceling?</Typography>
            <button
              onClick={handleClose}
              style={{
                backgroundColor: "transparent",
                border: "none",
                cursor: "pointer",
              }}
            >
              <img
                style={{
                  maxWidth: "20px",
                  objectFit: "contain",
                  cursor: "pointer",
                  zIndex: 1,
                }}
                src={CloseModalIcon}
                alt="close modal"
              />
            </button>
          </Box>
          <Typography
            alignItems={"center"}
            display={"flex"}
            justifyContent={""}
            variant="body1"
            fontSize={12}
          >
            Select one of the reasons
          </Typography>
          <StyledCancelButton
            disableRipple
            onClick={() => handleCancelReason("money")}
            className={cancelReason === "money" ? "active" : ""}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                mb: cancelReason === "money" ? "16px" : "0",
              }}
            >
              <img src={MoneyIcon} alt="money" className="icon-img" />
              <Typography variant="body1" color="#FCFCFC" fontSize={12}>
                Money
              </Typography>
            </Box>
            <Collapse sx={{ width: "100%" }} in={cancelReason === "money"}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                marginLeft={"54px"}
                gap={"24px"}
              >
                <Typography variant="body1" fontSize={12}>
                  {rateLowered
                    ? "You are already on the lowest rate."
                    : "Lower your rate to $7.50/month."}
                </Typography>
                <Button
                  fullWidth
                  variant={"contained"}
                  onClick={lowerMyRate}
                  disabled={rateLowered}
                >
                  {rateLowered ? "Rate lowered" : "Lower my rate"}
                </Button>
              </Box>
            </Collapse>
          </StyledCancelButton>
          <StyledCancelButton
            disableRipple
            onClick={() => handleCancelReason("expectations")}
            className={collapsed ? "active" : ""}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <img src={SadEmojiIcon} alt="Expectations" className="icon-img" />
              <Typography variant="body1" color="#FCFCFC" fontSize={12}>
                Didn&apos;t meet expectations
              </Typography>
            </Box>
            <Collapse sx={{ width: "100%" }} in={collapsed}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                marginLeft={5}
                width={"auto"}
              >
                <StyledExpectationButton
                  disableRipple
                  onClick={(e) => handleCancelSubReason(e, "bugs")}
                  className={cancelSubReason === "bugs" ? "active" : ""}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                  }}
                >
                  <Box>
                    <Radio size="small" checked={cancelSubReason === "bugs"} />
                    <span
                      style={{
                        fontSize: "11px",
                        lineHeight: "16px",
                        fontFamily: "Inter",
                        fontWeight: 400,
                      }}
                    >
                      Bugs/Crashes
                    </span>
                  </Box>
                  <Collapse
                    in={cancelSubReason === "bugs"}
                    sx={{
                      width: "100%",
                      pl: 2,
                      mt: "16px",
                    }}
                  >
                    <TextareaAutosize
                      minRows={4}
                      placeholder="We fix these immediately. Where was the bug?"
                      onChange={(e) =>
                        handleSetAdditionalNote(e, e.target.value)
                      }
                      value={additionalNote}
                      style={{
                        width: "100%",
                        backgroundColor: "transparent",
                        border: "1px solid rgba(255, 255, 255, 0.1)",
                        borderRadius: "8px",
                        color: "#fff",
                        fontFamily: "Inter",
                        fontWeight: 400,
                        padding: "10px 15px",
                        resize: "none",
                      }}
                    />
                  </Collapse>
                </StyledExpectationButton>
                <StyledExpectationButton
                  disableRipple
                  onClick={(e) => handleCancelSubReason(e, "features")}
                  className={cancelSubReason === "features" ? "active" : ""}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                  }}
                >
                  <Box>
                    <Radio
                      size="small"
                      checked={cancelSubReason === "features"}
                    />
                    <span
                      style={{
                        fontSize: "11px",
                        lineHeight: "16px",
                        fontFamily: "Inter",
                        fontWeight: 400,
                      }}
                    >
                      Missing features
                    </span>
                  </Box>
                  <Collapse
                    in={cancelSubReason === "features"}
                    sx={{
                      width: "100%",
                      pl: 2,
                      mt: "16px",
                    }}
                  >
                    <TextareaAutosize
                      minRows={4}
                      placeholder="What was missing? This message goes to the CEO."
                      onChange={(e) =>
                        handleSetAdditionalNote(e, e.target.value)
                      }
                      value={additionalNote}
                      style={{
                        width: "100%",
                        backgroundColor: "transparent",
                        border: "1px solid rgba(255, 255, 255, 0.1)",
                        borderRadius: "8px",
                        color: "#fff",
                        fontFamily: "Inter",
                        fontWeight: 400,
                        padding: "10px 15px",
                        resize: "none",
                      }}
                    />
                  </Collapse>
                </StyledExpectationButton>
                <StyledExpectationButton
                  disableRipple
                  onClick={(e) => handleCancelSubReason(e, "confusing")}
                  className={cancelSubReason === "confusing" ? "active" : ""}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                  }}
                >
                  <Box>
                    <Radio
                      size="small"
                      checked={cancelSubReason === "confusing"}
                    />
                    <span
                      style={{
                        fontSize: "11px",
                        lineHeight: "16px",
                        fontFamily: "Inter",
                        fontWeight: 400,
                      }}
                    >
                      Too confusing
                    </span>
                  </Box>
                  <Collapse
                    in={cancelSubReason === "confusing"}
                    sx={{
                      width: "100%",
                      pl: 2,
                      mt: "16px",
                    }}
                  >
                    <TextareaAutosize
                      minRows={4}
                      placeholder="What were you trying to do that didn't work? This message goes to the CEO."
                      onChange={(e) =>
                        handleSetAdditionalNote(e, e.target.value)
                      }
                      value={additionalNote}
                      style={{
                        width: "100%",
                        backgroundColor: "transparent",
                        border: "1px solid rgba(255, 255, 255, 0.1)",
                        borderRadius: "8px",
                        color: "#fff",
                        fontFamily: "Inter",
                        fontWeight: 400,
                        padding: "10px 15px",
                        resize: "none",
                      }}
                    />
                  </Collapse>
                </StyledExpectationButton>
              </Box>
            </Collapse>
          </StyledCancelButton>
          <StyledCancelButton
            disableRipple
            onClick={() => handleCancelReason("no use")}
            className={cancelReason === "no use" ? "active" : ""}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <img src={HeartIcon} alt="like it" className="icon-img" />
              <Typography variant="body1" color="#FCFCFC" fontSize={12}>
                Like it, but didn&apos;t use it enough
              </Typography>
            </Box>
            <Collapse
              in={cancelReason === "no use"}
              sx={{
                width: "100%",
                pl: "50px",
                mt: "16px",
              }}
            >
              <TextareaAutosize
                minRows={4}
                placeholder="Share with us your experience. This message goes to the CEO."
                onChange={(e) => handleSetAdditionalNote(e, e.target.value)}
                value={additionalNote}
                style={{
                  width: "100%",
                  backgroundColor: "transparent",
                  border: "1px solid rgba(255, 255, 255, 0.1)",
                  borderRadius: "8px",
                  color: "#fff",
                  fontFamily: "Inter",
                  fontWeight: 400,
                  padding: "10px 15px",
                  resize: "none",
                }}
              />
            </Collapse>
          </StyledCancelButton>
          <Divider
            sx={{
              width: "100%",
            }}
          />
          <Box display={"flex"} justifyContent={"space-between"} gap={2}>
            <Button
              fullWidth
              sx={{
                backgroundColor: "#37383c",
                color: "#F3F3F5",
                borderColor: "#37383c",
                "&:hover": {
                  borderColor: "#37383c",
                },
              }}
              variant={"outlined"}
              onClick={handleClose}
            >
              Close
            </Button>
            <Button
              fullWidth
              variant={"contained"}
              onClick={() => cancelSubscription()}
              disabled={cancelReason === ""}
              sx={{
                "&:disabled": {
                  color: "rgba(2, 2, 3, 0.5)",
                },
              }}
            >
              Cancel subscription
            </Button>
          </Box>
        </StyledAccountContent>
      </Box>
    </Modal>
  );
};
