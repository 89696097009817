import { Navigate, useSearchParams } from "react-router-dom";

export const PublicRoute = ({ children }: any) => {
  const [searchParams] = useSearchParams();
  if (
    !localStorage.getItem("access_token") ||
    (localStorage.getItem("onboarding_in_progress") &&
      !localStorage.getItem("user_email_login")) ||
    searchParams.get("from") === "email" ||
    window.location.pathname === "/hardware" ||
    window.location.pathname === "/rec-setup" ||
    window.location.pathname === "/pricing" ||
    window.location.pathname === "/"
  ) {
    return children;
  }

  return <Navigate to={`/account${window.location.search}`} />;
};
