import React, { useEffect, useState } from "react";
import { EmailStep } from "../../components/Waitlist/EmailStep";
import { WaitlistHeader } from "../../components/Waitlist/WaitlistHeader";

import { StyledAuthContainer } from "../../components/micro/micro.styled";
import { api } from "../../api/base";
import { InstagramStep } from "../../components/Waitlist/InstagramStep";
import { WaitlistCompleted } from "../../components/Waitlist/WaitlistCompleted";
import { useDispatch } from "react-redux";
import { analyticsEvent } from "../../redux/actions/analytics";

export const WaitlistPage = () => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(
    parseInt(localStorage.getItem("waitlistStep") || "0"),
  );
  const [userData, setUserData] = useState({
    email: "",
    instagramLink: "instagram.com/",
  });
  const [formError, setFormError] = useState("");
  const [userExists, setUserExists] = useState(false);
  const [instagramHasInput, setInstagramHasInput] = useState(false);
  const [waitlistNumber, setWaitlistNumber] = useState(0);

  useEffect(() => {
    // Waitlist: Started event
    dispatch(
      analyticsEvent("PAGE_VISIT", "Waitlist: Started", {
        page: "Waitlist Page",
      }),
    );
  }, []);

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.name === "instagramLink") {
      if (e.target.value === "") {
        setUserData({
          ...userData,
          [e.target.name]: "instagram.com/",
        });
        setInstagramHasInput(false);
        return;
      }
      if (e.target.value.startsWith("instagram.com/")) {
        e.target.value = e.target.value.replace("https://instagram.com/", "");
        e.target.value = e.target.value.replace(
          "https://www.instagram.com/",
          "",
        );
        if (e.target.value !== "instagram.com/") {
          e.target.value = e.target.value.replace(/\/$/, "");
        }
        setUserData({
          ...userData,
          [e.target.name]: e.target.value,
        });
        setInstagramHasInput(e.target.value.length > "instagram.com/".length);
      }
    } else {
      setUserData({
        ...userData,
        [e.target.name]: e.target.value,
      });
    }
    setInstagramHasInput(e.target.value.length > "instagram.com/".length);
    setFormError("");
  };

  const onNextKeyPress = (e: any): void => {
    if (e.key === "Enter") {
      if (e.target?.name === "email") {
        checkUserEmail();
      }
    }
  };

  const checkUserEmail = (): boolean | void => {
    const isEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(
      userData.email.toLowerCase(),
    );

    if (!isEmail) {
      setFormError("Please use a valid email address");
      setUserExists(false);
      return false;
    }
    dispatch(
      analyticsEvent("INPUT", "Waitlist: Email Entered", {
        page: "Watlist Email Page",
        email: userData.email,
      }),
    );
    localStorage.setItem("waitlistStep", "1");
    localStorage.setItem("waitlistEmail", userData.email);
    setStep(1);
    setFormError("");
  };

  const checkInstagramLink = (fromHeader = false): boolean | void => {
    const isInstagramLink = /^instagram.com\/[a-zA-Z0-9_.]+$/.test(
      userData.instagramLink.toLowerCase(),
    );

    if (!isInstagramLink) {
      setFormError("Please use a valid instagram link");
      return false;
    }

    localStorage.setItem("waitlistInstagramLink", userData.instagramLink);

    if (!fromHeader) {
      submitToWaitlist();
    } else {
      localStorage.setItem("waitlistStep", "2");
      setStep(2);
      return true;
    }
  };

  const handleBackButton = (): void => {
    setStep(0);
    localStorage.setItem("waitlistStep", "0");
  };

  const submitToWaitlist = async (): Promise<void> => {
    const response = await api.post("waitlist", { ...userData });
    if (response.status === 201) {
      setWaitlistNumber(response.data.count);
      localStorage.setItem("waitlistNumber", response.data.count);
      localStorage.setItem("waitlistStep", "2");
      localStorage.removeItem("waitlistEmail");
      localStorage.removeItem("waitlistInstagramLink");
      setStep(2);
      setFormError("");
      dispatch(
        analyticsEvent("INPUT", "Waitlist: Instagram Entered", {
          page: "Watlist Instagram Page",
          email: userData.instagramLink,
        }),
      );
      (window as any).fbq("trackSingle", "1476539539591438", "Lead", {
        "ZW1haWw=": window.btoa(userData.email),
        "aW5zdGFncmFtTGluaw==": window.btoa(userData.instagramLink),
      });
    } else {
      setFormError("Something went wrong, please try again later");
    }
  };

  const handleStep = async (stepNumber: number = 0) => {
    if (step === 0 && stepNumber === 1) {
      if (!checkUserEmail()) return false;
    }
    if (step === 0 && stepNumber === 2) {
      if (!checkUserEmail()) return false;
    }
    if (step === 1 && stepNumber === 2) {
      if (!checkInstagramLink(true)) return false;
    }

    setStep(stepNumber);
  };

  useEffect(() => {
    const emailFromLocalStorage = localStorage.getItem("waitlistEmail");
    if (emailFromLocalStorage) {
      setUserData({
        ...userData,
        email: emailFromLocalStorage,
      });
    }
    const instagramLinkFromLocalStorage = localStorage.getItem(
      "waitlistInstagramLink",
    );
    if (instagramLinkFromLocalStorage) {
      setUserData({
        ...userData,
        instagramLink: instagramLinkFromLocalStorage,
      });
    }
    setFormError("");
  }, [step]);

  return (
    <StyledAuthContainer>
      <WaitlistHeader
        step={step}
        showBackButton={step === 1}
        backButtonHandler={handleBackButton}
        handleStep={handleStep}
      />
      {step === 0 && (
        <EmailStep
          userData={userData}
          nextUserFunction={checkUserEmail}
          onChangeHandler={onChangeHandler}
          userExists={userExists}
          formError={formError}
          onNextKeyPress={onNextKeyPress}
        />
      )}
      {step === 1 && (
        <InstagramStep
          onChangeHandler={onChangeHandler}
          userData={userData}
          nextUserFunction={checkInstagramLink}
          formError={formError}
          instagramHasInput={instagramHasInput}
          onNextKeyPress={onNextKeyPress}
        />
      )}
      {step === 2 && <WaitlistCompleted waitlistNumber={waitlistNumber} />}
    </StyledAuthContainer>
  );
};
