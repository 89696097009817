import { styled, Box, BoxProps } from "@mui/material";

export const StyledLandingPageWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  background: "#000",
  position: "relative",
  overflowX: "hidden",

  [theme.breakpoints.down("md")]: {
    ".density-hero-heading": {
      fontSize: "30px",
      fontWeight: "900",
      lineHeight: "34px",
      letterSpacing: "0em",
      textAlign: "center",
    },
    ".density-hero-button": {
      marginBottom: "0px",
    },
  },
}));

export const StyledLandingPageContainer = styled(Box)<BoxProps>(
  ({ theme }) => ({
    maxWidth: "1360px",
    margin: "0 auto",
    padding: "0 80px",
    width: "100%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    gap: "145px",
    paddingTop: "64px",
    [theme.breakpoints.down("md")]: {
      padding: "0 20px",
      gap: "70px",
      paddingTop: "70px",
    },

    " .hardware-density-image": {
      maxWidth: 820,
      maxHeight: 616,
      top: 66,
      left: 271,
      margin: "auto",
    },
  }),
);

export const StyledDensityHardwareContainer = styled(Box)<BoxProps>(
  ({ theme }) => ({
    maxWidth: "1360px",
    // margin: "0 auto",
    // padding: "0 80px",
    width: "100%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    paddingTop: "67px",

    [theme.breakpoints.up("md")]: {
      backgroundColor: "green",
    },
    " .hardware-density-image": {
      maxWidth: 820,
      maxHeight: 616,
      margin: "auto auto -34px",
    },

    // ".hardware-density-heading": {
    //   width: "13ch",
    // },
    [theme.breakpoints.up("md")]: {
      ".hardware-density-heading": {
        fontSize: 60,
        lineHeight: "90px",
      },
    },
    [theme.breakpoints.down("md")]: {
      ".hardware-density-heading": {
        fontSize: 37,
        lineHeight: "37px",
      },
    },
  }),
);

export const StyledLandingHeroSection = styled(Box)<BoxProps>(({ theme }) => ({
  margin: "auto",
  maxWidth: "1000px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

export const StyledLandingPageFooter = styled(Box)<BoxProps>(({ theme }) => ({
  marginTop: "40px",
  borderTop: "1px solid rgba(252, 252, 252, 0.1)",
  width: "100%",
  "& .footer-content": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    padding: "24px 20px",
    maxWidth: "1360px",
  },
  "& .footer-text-wrapper": {
    order: 3,
  },
  "& .footer-social-wrapper": {
    order: 2,
  },

  " .footer-text": {
    fontFamily: "Inter",
    fontSize: "9px",
    fontWeight: "500",
    lineHeight: "16px",
    letterSpacing: "1.5px",
    color: "#7F849B",
    marginBottom: "0px",
    textAlign: "left",
    "&:first-child": {
      marginTop: "24px",
    },

    " a": {
      color: "#9FA1B3",
    },
  },
  [theme.breakpoints.up("md")]: {
    marginTop: "100px",
    "& .footer-content": {
      padding: "20px 88px",
      flexWrap: "nowrap",
      margin: "0 auto",
    },
    "& .footer-text-wrapper": {
      order: 2,
    },
    "& .footer-social-wrapper": {
      order: 3,
    },
    " .footer-text": {
      textAlign: "center",
      marginBottom: "0px",
      "&:last-child": {
        marginTop: "0px",
      },
      "&:first-child": {
        marginTop: "0px",
      },
    },
  },
}));

export const StyledLandingBackgroundTopEffect = styled("img")<BoxProps>(
  ({ theme }) => ({
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    objectFit: "contain",
    height: "400px",
    zIndex: 0,
    [theme.breakpoints.down("md")]: {
      height: "200px",
      objectFit: "cover",
    },
  }),
);

export const StyledLandingBoldParagraph = styled("p")<BoxProps>(
  ({ theme }) => ({
    fontFamily: "PPRightGrotesk-WideMedium",
    fontSize: "23px",
    lineHeight: "26px",
    color: "#C1C5DD",
    zIndex: 2,
    background:
      "linear-gradient(95.5deg, rgba(255, 255, 255, 0.5) 0.43%, rgba(255, 255, 255, 0) 110.27%)",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    maxWidth: "250px",
    marginRight: "70px",
    alignSelf: "flex-end",
  }),
);

export const StyledLandingVideoWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  height: "650px",
  borderRadius: "30px",
  overflow: "hidden",
  position: "relative",
  flexDirection: "column",
  width: "100%",
  marginLeft: "auto",
  marginRight: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    background: "linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.8) 70%)",
    zIndex: 1,
  },
  " .landing-video-image": {
    height: "100%",
    width: "100%",
    objectFit: "cover",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 0,
  },
  " .landing-video-play-icon": {
    height: "90px",
    width: "100px",
    border: "none",
    background: "transparent",
    zIndex: 5,
    cursor: "pointer",
    top: "50%",
    left: "50%",
    position: "absolute",
    transform: "translate(-50%, -50%)",
    "& img": {
      height: "100%",
      width: "100%",
      objectFit: "contain",
    },
  },
  [theme.breakpoints.down("md")]: {
    height: "165px",
    maxWidth: "100%",
    alignItems: "center",
    justifyContent: "flex-end",
    " .landing-video-play-icon": {
      height: "47px",
      width: "57px",
    },
    p: {
      fontSize: "12px",
      alignSelf: "center",
      margin: "0px",
      marginBottom: "12px",
      background:
        "linear-gradient(95.5deg, rgba(255, 255, 255, 0.5) 0.43%, rgba(255, 255, 255, 0) 110.27%)",
      "-webkit-background-clip": "text",
      "-webkit-text-fill-color": "transparent",
      lineHeight: "13.7px",
    },
    "&::before": {
      background:
        "linear-gradient(89.6deg, rgba(18, 19, 32, 0.95) 11.5%, rgba(0, 0, 0, 0) 99.96%, rgba(0, 0, 0, 0) 99.96%)",
    },
  },
}));

export const StyledHeroWithText = styled(Box)<BoxProps>(({ theme }) => ({
  height: "500px",
  maxWidth: "100%",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: "32px",
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  overflow: "hidden",
  position: "relative",
  borderRadius: "20px",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    zIndex: 1,
  },
  [theme.breakpoints.up("sm")]: {
    height: "550px",
    borderRadius: "30px",
    overflow: "hidden",
    flexDirection: "column",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&::after": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      height: "100%",
      width: "100%",
      background:
        "linear-gradient(-104.38deg, rgba(111, 118, 255, 0.2) 0.76%, rgba(111, 118, 255, 0) 58.2%)",
      zIndex: 1,
    },
  },
}));

type StyledHeroWithTextContentProps = {
  direction: "left" | "right";
} & BoxProps;

export const StyledHeroWithTextContent = styled(
  Box,
)<StyledHeroWithTextContentProps>((props) => ({
  zIndex: 2,
  position: "relative",
  maxWidth: "100%",
  width: "100%",
  alignSelf: "center",
  marginLeft: "auto",
  marginRight: "auto",
  "& .hero-text-subtitle": {
    fontFamily: "IBM Plex Mono",
    color: "#575F9E",
    fontSize: "11px",
    fontWeight: 600,
    lineHeight: "16px",
    letterSpacing: "1.25px",
    marginBottom: "16px",
  },
  "& .hero-text": {
    fontFamily: "Inter",
    color: "#7F849B",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "20px",
    letterSpacing: "0.1px",
    marginBottom: "20px",
  },
  h6: {
    fontSize: "21px",
    lineHeight: "24px",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    maxWidth: "220px",
  },

  [props.theme.breakpoints.up("sm")]: {
    maxWidth: props.direction === "left" ? "510px" : "390px",
    alignSelf: props.direction === "left" ? "flex-start" : "flex-end",
    marginLeft: props.direction === "left" ? "100px" : 0,
    marginRight: props.direction === "left" ? "0" : "100px",
    h6: {
      fontSize: "35px",
      lineHeight: "40px",
      "-webkit-background-clip": "text",
      "-webkit-text-fill-color": "transparent",
      maxWidth: "100%",
    },
    "& .hero-text": {
      fontSize: "14px",
      lineHeight: "24px",
      color: "#9EA1B2",
    },
    "& .hero-text-subtitle": {
      fontFamily: "IBM Plex Mono",
      color: "#575F9E",
      fontSize: "12px",
      fontWeight: 600,
      lineHeight: "16px",
      letterSpacing: "1.25px",
      marginBottom: "24px",
    },
  },
}));

export const StyledEditYourSetWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "68px",
  " .edit-set-addon": {
    fontFamily: "IBM Plex Mono",
    fontSize: "11px",
    fontWeight: "600",
    lineHeight: "16px",
    letterSpacing: "1.25px",
    textAlign: "left",
    color: "#575F9E",
    marginBottom: "16px",
    marginTop: "0px",
  },
  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
    height: "620px",
    gap: "20px",
    " .edit-set-addon": {
      display: "none",
    },
  },
}));

export const StyledEditYourSetFirstItem = styled(Box)<BoxProps>(
  ({ theme }) => ({
    flexBasis: "100%",
    background: "rgba(15, 15, 15, .7)",
    height: "100%",
    borderRadius: "30px",
    paddingTop: "50px",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    h6: {
      fontSize: "21px",
      textAlign: "left",
      lineHeight: "24px",
      padding: "0 30px",
      maxWidth: "270px",
    },
    p: {
      padding: "0 30px",
      fontSize: "12px",
      lineHeight: "20px",
      color: "#7f849b",
      marginBottom: "36px",
      maxWidth: "280px",
    },
    img: {
      marginTop: "auto",
      marginLeft: "auto",
      width: "90%",
      display: "block",
      objectFit: "contain",
    },
    [theme.breakpoints.up("sm")]: {
      flexBasis: "40%",
      h6: {
        textAlign: "center",
        fontSize: "24px",
        maxWidth: "326px",
        margin: "0 auto",
        lineHeight: "27.42px",
        marginBottom: "16px",
      },
      p: {
        maxWidth: "326px",
        textAlign: "center",
        margin: "0 auto",
        fontSize: "13px",
        lineHeight: "22px",
      },
      img: {
        height: "340px",
      },
    },
  }),
);

export const StyledEditYourSetSecondItem = styled(Box)<BoxProps>(
  ({ theme }) => ({
    flexBasis: "100%",
    background: "rgba(15, 15, 15, .7)",
    height: "100%",
    borderRadius: "30px",
    paddingTop: "60px",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    "& .move-track-animation": {
      width: "100%",
      display: "block",
      objectFit: "contain",
      marginBottom: "60px",
      height: "150px",
      order: 3,
    },
    h6: {
      fontSize: "21px",
      lineHeight: "24px",
      marginBottom: "16px",
      padding: "0 30px",
      order: 2,
      maxWidth: "230px",
    },
    p: {
      color: "#7f849b",
      fontSize: "12px",
      lineHeight: "20px",
      marginBottom: "40px",
      padding: "0 30px",
      order: 2,
      maxWidth: "285px",
    },
    [theme.breakpoints.up("sm")]: {
      flexBasis: "60%",
      "& .move-track-animation": {
        height: "310px",
        order: 1,
      },
      h6: {
        textAlign: "center",
        fontSize: "24px",
        maxWidth: "430px",
        margin: "0 auto",
        lineHeight: "27.42px",
        marginBottom: "16px",
      },
      p: {
        maxWidth: "380px",
        textAlign: "center",
        margin: "0 auto",
        fontSize: "13px",
        lineHeight: "22px",
      },
    },
  }),
);

export const StyledDensityFeaturesWrapper = styled(Box)<BoxProps>(
  ({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: "68px",
    [theme.breakpoints.up("sm")]: {
      height: "627px",
      flexDirection: "row",
      gap: "20px",
    },
  }),
);

export const StyledDensityFeaturesContainer = styled(Box)<BoxProps>(
  ({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: "68px",
    [theme.breakpoints.up("sm")]: {
      gap: "20px",
    },
  }),
);

export const StyledDensityFeatureFullImageItem = styled(Box)<BoxProps>(
  ({ theme }) => ({
    flexBasis: "100%",
    height: "100%",
    borderRadius: "30px",
    paddingTop: "45px",
    background: "rgba(15, 15, 15, .7)",
    img: {
      width: "100%",
      display: "block",
      objectFit: "contain",
      marginBottom: "35px",
    },
    svg: {
      width: "100%",
      display: "block",
      objectFit: "contain",
      marginBottom: "35px",
    },
    h6: {
      textAlign: "center",
      fontSize: "21px",
      lineHeight: "24px",
      marginBottom: "16px",
      color: "#C1C5DD",
      padding: "0 30px",
    },
    p: {
      textAlign: "center",
      margin: "0 auto",
      fontSize: "12px",
      lineHeight: "20px",
      color: "#7f849b",
      padding: "0 30px",
      paddingBottom: "40px",
    },
    ":last-child": {
      img: {
        width: "80%",
        margin: "0 auto 35px auto",
      },
      svg: {
        width: "80%",
        margin: "0 auto 35px auto",
      },
    },
    [theme.breakpoints.up("sm")]: {
      paddingTop: "60px",
      flexBasis: "60%",
      img: {
        height: "394px",
        marginBottom: "48px",
      },
      svg: {
        height: "394px",
        marginBottom: "48px",
      },
      ":last-child": {
        img: {
          height: "394px",
          marginBottom: "48px",
        },
        svg: {
          height: "394px",
          marginBottom: "48px",
        },
      },
      h6: {
        textAlign: "center",
        padding: "0px",
        fontSize: "24px",
        maxWidth: "380px",
        margin: "0 auto",
        lineHeight: "27.42px",
        marginBottom: "16px",
      },
      p: {
        maxWidth: "380px",
        fontSize: "13px",
        lineHeight: "22px",
        padding: "0px",
      },
    },
  }),
);

export const StyledDensityFeaturesCroppedImageItem = styled(Box)<BoxProps>(
  ({ theme }) => ({
    flexBasis: "100%",
    height: "100%",
    borderRadius: "30px",
    paddingTop: "60px",
    background: "rgba(15, 15, 15, .7)",
    display: "flex",
    flexDirection: "column",
    paddingBottom: "60px",
    h6: {
      textAlign: "center",
      fontSize: "21px",
      lineHeight: "24px",
      marginBottom: "16px",
      color: "#C1C5DD",
      padding: "0 30px",
    },
    p: {
      textAlign: "center",
      margin: "0 auto",
      fontSize: "12px",
      lineHeight: "20px",
      color: "#7f849b",
      padding: "0 30px",
      paddingBottom: "40px",
    },
    img: {
      width: "90%",
      display: "block",
      marginLeft: "auto",
      objectFit: "contain",
    },
    [theme.breakpoints.up("sm")]: {
      flexBasis: "40%",
      paddingBottom: "0px",
      h6: {
        textAlign: "center",
        padding: "0px",
        fontSize: "24px",
        maxWidth: "380px",
        margin: "0 auto",
        lineHeight: "27.42px",
        marginBottom: "16px",
      },
      p: {
        maxWidth: "380px",
        fontSize: "13px",
        lineHeight: "22px",
        padding: "0px",
      },
      img: {
        marginTop: "auto",
        marginLeft: "auto",
        width: "86%",
        display: "block",
        height: "413px",
      },
    },
  }),
);

export const StyledMasterSetItem = styled(Box)<BoxProps>(({ theme }) => ({
  flexBasis: "50%",
  background: "#FFFFFF0D",
  height: "100%",
  borderRadius: "30px",
  position: "relative",
  p: {
    padding: "0 30px",
    fontSize: "12px",
    lineHeight: "20px",
    color: "#7f849b",
    textAlign: "center",
    marginBottom: "40px",
  },
  img: {
    width: "100%",
    display: "block",
    objectFit: "contain",
    marginBottom: "35px",
  },
  h6: {
    color: "#C1C5DD",
    textAlign: "center",
    fontSize: "30px",
    lineHeight: "32px",
    padding: "0 30px",
    margin: "0 auto",
    marginBottom: "16px",
  },
  " .master-set-button": {
    fontFamily: "Inter",
    fontWeight: "500",
    letterSpacing: "0px",
    color: "rgba(255, 255, 255, 0.5)",
    textTransform: "uppercase",
    border: "1px solid rgba(255, 255, 255, 0.1)",
    borderRadius: "30px",
    width: "120px",
    fontSize: "8px",
    padding: "5px 10px",
    marginLeft: "30px",
    marginTop: "0px",
  },
  " .master-padding-top": {
    paddingTop: "60px",
  },
  " .lottie-animation": {
    maxHeight: "280px",
    overflow: "hidden",
  },
  [theme.breakpoints.up("sm")]: {
    " .lottie-animation": {
      maxHeight: "370px",
      marginBottom: "60px",
      overflow: "hidden",
    },
    " .master-padding-top": {
      paddingTop: "60px",
    },
    p: {
      maxWidth: "380px",
      margin: "0 auto",
      fontSize: "13px",
      padding: "0px",
      lineHeight: "22px",
    },
    img: {
      width: "100%",
      display: "block",
      height: "310px",
      objectFit: "contain",
      marginBottom: "60px",
    },
    h6: {
      padding: "0px",
      fontSize: "24px",
      maxWidth: "380px",
      lineHeight: "27.42px",
    },
    " .master-set-button": {
      fontSize: "10px",
      lineHeight: "23px",
      width: "140px",
      padding: "5px 10px",
      marginLeft: "46px",
    },
  },
}));

export const StyledFreeTrialWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  borderRadius: "30px",
  overflow: "hidden",
  position: "relative",
  width: "100%",
  marginLeft: "auto",
  marginRight: "auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  paddingTop: "50px",
  background:
    "linear-gradient(180deg, #686FEF 0%, rgba(104, 111, 239, 0) 100%)",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    background:
      "linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.4) 100%)",
    zIndex: 1,
  },
  img: {
    display: "block",
    objectFit: "contain",
    marginBottom: "35px",
    width: "100%",
    alignSelf: "flex-end",
    paddingLeft: "30px",
  },
  h6: {
    fontSize: "30px",
    lineHeight: "34px",
    padding: "10px 30px 0",
    position: "relative",
    zIndex: 2,
  },
  button: {
    margin: "30px",
    width: "calc(100% - 60px)",
  },
  [theme.breakpoints.up("sm")]: {
    padding: "54px 54px 0 54px",
    alignItems: "center",
    height: "400px",
    flexDirection: "row",
    img: {
      height: "330px",
      width: "710px",
      objectFit: "contain",
    },
    "&::before": {
      background:
        "linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.8) 100%)",
    },
    h6: {
      width: "290px",
      padding: "0px",
    },
    button: {
      marginTop: "50px",
      marginBottom: "70px",
      marginLeft: "0px",
      marginRight: "0px",
      width: "250px",
    },
  },
}));
