import { tryReadParamGroup } from "../utils/param.group";
import { loadLocalJSON, storeLocalJSON } from "./local.storage";

export interface UtmParams {
  utm_source: string;
  utm_medium: string;
  utm_campaign: string;
  utm_content: string;
  link_domain: string;
  link_slug: string;
}

const tryGetNonEmptyUtmParams = (
  searchParams: URLSearchParams,
): UtmParams | null => {
  return tryReadParamGroup(searchParams, [
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_content",
    "link_domain",
    "link_slug",
  ]);
};

export const rememberUtmParams = (searchParams: URLSearchParams) => {
  const utmParamsOpt = tryGetNonEmptyUtmParams(searchParams);
  if (utmParamsOpt) {
    storeLocalUtmParams(utmParamsOpt);
  }
};

export const storeLocalUtmParams = (utmParams: UtmParams) => {
  storeLocalJSON("utm_params", utmParams);
};

export const loadLocalUtmParams = (): any | null => {
  return loadLocalJSON("utm_params");
};
