import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography, InputLabel, TextField } from "@mui/material";

import { useUserContext } from "../../context/UserContext";
import {
  StyledAccountContent,
  StyledDashContainer,
} from "../../components/micro/micro.styled";
import { Header } from "../../components/Header";

export const CanceledPage = (): JSX.Element => {
  const navigate = useNavigate();
  return (
    <StyledDashContainer>
      <Header />
      <Box
        gap={"20px"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        display={"flex"}
        height={"100%"}
        width={"100%"}
      >
        <StyledAccountContent>
          <Typography variant={"h5"}>Your subscription was canceled</Typography>
          <Typography variant={"body1"}>
            You have access to Density until Feb 18, 2023.
          </Typography>
          <Typography mt={2} mb={6} variant={"body1"}>
            When the access expires, you will lose the access to the sets
            you&apos;ve created. You can save them locally on your computer and
            continue using after renewing the subscription.
          </Typography>
          <Box display={"flex"} justifyContent={"space-between"} gap={2}>
            <Button variant={"outlined"} onClick={() => null}>
              Keep subscription
            </Button>
            <Button variant={"contained"} onClick={() => navigate("/account")}>
              Go back to account page
            </Button>
          </Box>
        </StyledAccountContent>
      </Box>
    </StyledDashContainer>
  );
};
