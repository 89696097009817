import { styled, Box, BoxProps } from "@mui/material";

export const StyledHeaderContainer = styled("header")<BoxProps>(
  ({ theme }) => ({
    padding: "24px 60px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    position: "relative",
    borderBottom: "1px solid #232529",
    img: {
      maxHeight: "20px",
      width: "auto",
      marginRight: "50px",
      "&.expand-icon": {
        marginRight: "0",
      },
    },
    button: {
      marginLeft: "16px",
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      padding: "24px 20px",
      img: {
        marginBottom: "10px",
      },
      button: {
        marginLeft: "auto",
      },
    },
  }),
);
