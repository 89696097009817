import {
  takeEvery,
  call,
  put,
  takeLeading,
  takeLatest,
  delay,
} from "redux-saga/effects";
import { sessionCreated } from "../../actions/app";
import {
  fetchSession,
  downloadApp,
  setReminder,
  sendDownloadEmail,
} from "./services";
import { analyticsEvent } from "../../actions/analytics";

function* createUserSession(action: { payload: any; type: string }) {
  const { payload } = action;

  try {
    const savedSession = localStorage.getItem("session");
    const session = savedSession ? JSON.parse(savedSession) : null;
    if (isSessionExpired(session)) {
      const { sessionId, id, userId, expiresAt } = yield call(
        fetchSession,
        payload.userId,
      );
      yield put(sessionCreated(sessionId));
      localStorage.setItem(
        "session",
        JSON.stringify({ sessionId, id, userId, expiresAt }),
      );
      localStorage.setItem("sessionId", sessionId);
    }
  } catch (error) {
    console.log(error);
  }
}

function isSessionExpired(session: any) {
  if (!session) return true;
  const now = new Date();
  const expiresAt = new Date(session.expiresAt);
  return expiresAt < now;
}

function* handleDownloadApp(action: { payload: any; type: string }) {
  yield delay(1000);
  window.location.assign(
    "https://release.density.one/Density-Installer-Universal-Latest.dmg",
  );
  yield call(
    downloadApp,
    action.payload.email,
    action.payload.user_id,
    "email",
  );
}

function* handleSetReminder(action: { payload: any; type: string }) {
  yield call(setReminder, action.payload.user_id, action.payload.reminderType);
}

function* handleExistingUser(action: {
  payload: {
    email: string;
  };
  type: string;
}) {
  yield call(sendDownloadEmail, action.payload.email);
}

export function* appSaga() {
  yield takeLeading("APPLICATION_START", createUserSession);
  yield takeLeading("DOWNLOAD_APP", handleDownloadApp);
  yield takeLeading("SET_REMINDER", handleSetReminder);
  yield takeLatest("USER_EXISTS", handleExistingUser);
}
