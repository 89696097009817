import { FaqCardProps } from "../../components/Info/FaqCard/types";
import {
  ActionElementType,
  InfoCardProps,
} from "../../components/Info/InfoCardWithAction/types";
import { InfoPanelProps } from "../../components/Info/InfoPanel/types";
import { InfoshowcaseProps } from "../../components/Info/InfoShowcase/types";

export interface RecSetupTexts {
  FIRST_INFO_PANEL: InfoPanelProps;
  SECOND_INFO_PANEL: InfoPanelProps;
  STEP_ONE: InfoPanelProps;
  STEP_TWO: InfoPanelProps;
  STEP_THREE: InfoPanelProps;
  STEP_FOUR: InfoPanelProps;
  STEP_FIVE: InfoshowcaseProps;
  STEP_SIX: InfoshowcaseProps;
  STEP_SEVEN: InfoshowcaseProps;
  STEP_EIGHT: InfoshowcaseProps;
  STEP_FINAL: InfoshowcaseProps;
  RETRIEVING_STEP_ONE: InfoshowcaseProps;
  RETRIEVING_STEP_TWO: InfoshowcaseProps;
  RETRIEVING_STEP_THREE: InfoshowcaseProps;
  RETRIEVING_STEP_FOUR: InfoshowcaseProps;
  RETRIEVING_STEP_FIVE: InfoshowcaseProps;
  SAFE_FOR_ARTISTS: InfoshowcaseProps;
  FAQ: FaqCardProps[];
}

export const TEXTS: RecSetupTexts = {
  FIRST_INFO_PANEL: {
    title: "The full setup",
  },
  SECOND_INFO_PANEL: {
    title: "Tech Rider",
  },
  STEP_ONE: {
    heading: "Ensure the power cable is pushed all the way into the device.",
    description:
      "If it is partially pushed in, the device will overheat and shut off. After you ensure everything is in place, to push the power button.",
  },
  STEP_TWO: {
    heading: "When the device is ready, it will say: ",
    description: "Mixer: Disconnected    Decks: Disconnected",
  },
  STEP_THREE: {
    heading: "Connect the Rec-1 via ethernet  to the network switch.",
    description:
      "It can take fourteen seconds to resolve on the network. When it’s done it will say Decks: Connected",
  },
  STEP_FOUR: {
    heading: "Deck settings",
    description: `Continius mode: Off
Auto-cue: On`,
  },
  STEP_FIVE: {
    title: ``,
    description: "",
  },
  STEP_SIX: {
    title: `hello`,
    description: "",
  },
  STEP_SEVEN: {
    title: `When the screen displays “Decks and Mixer  Connected”, it’s ready to record.`,
    description:
      "Data is only captured when something is happening on the Decks. The device can be left on until showtime without wasting space.",
  },
  STEP_EIGHT: {
    title: `Press and hold the button to power down the device`,
    description:
      "Pulling out the power cord will eventually damage the device. Do this once per day. Letting the device run for days may cause recording errors.",
    rtl: true,
  },
  STEP_FINAL: {
    title: `No audio is recorded, just the  metadata.`,
    description:
      "When opening a set, the DJ points Density to their music folder, and the set is recreated.",
  },
  RETRIEVING_STEP_ONE: {
    title: `At the end of the night plug a USB stick into the Rec-1 to retrive the sets.`,
    description: "",
  },
  RETRIEVING_STEP_TWO: {
    title: `Do not remove the USB until it says “Done”.`,
    description:
      "The screen will let you know when it’s safe to remove the USB stick. Removing the USB before it says “Done” will damage the device.",
  },
  RETRIEVING_STEP_THREE: {
    title: `At the end of the night plug a USB stick into the Rec-1 to retrive the sets.`,
    description: "",
    // description: 'When opening a set, the DJ points Density to their music folder, and the set is recreated.',
  },
  RETRIEVING_STEP_FOUR: {
    title: `Open the Density software and plug the USB into your computer.`,
    description: "",
    // description: 'When opening a set, the DJ points Density to their music folder, and the set is recreated.',
  },
  RETRIEVING_STEP_FIVE: {
    title: `At the end of the night plug a USB stick into the Rec-1 to retrive the sets.`,
    description: "",
    // description: 'When opening a set, the DJ points Density to their music folder, and the set is recreated.',
  },
  SAFE_FOR_ARTISTS: {
    title: `Only the artist is capable
of opening up their set.`,
    description: `Because we don’t record any audio, all of the files played during the set must be present on the computer for it to open. Unlike traditional audio recording, Density is typically compliant with the artists riders.`,
  },
  FAQ: [
    {
      question: "Why aren’t the Decks connecting?",
      answer:
        "It can take up to one minute and 14 seconds to resolve on the network.",
    },
    {
      question: "Why isn’t the Mixer connecting?",
      answer:
        "The midi button must be pressed on the mixer AND a fader or knob must be moved to send midi data. If that doesn’t work, try using another USB cable. If you are still experiencing problems, plug a USB stick into the device. If the USB stick is not recognized, the port has been damaged and the device needs to be replaced.",
    },
    {
      question: "Does it work with Xone mixers?",
      answer:
        "No. Unfortunately, they did not fully midi map the mixer, so there is nothing for us to capture.",
    },
    {
      question: "Does it work with Traktor/Serato?",
      answer: "No. There is no data output for us to record.",
    },
    {
      question: "Does it record audio?",
      answer: [
        `No. It only records track metadata, cues, loops, and mixer data. When the DJ opens it in the Density DAW, they will be asked to point to their DJ folder. This will populate the audio files in the DAW.`,
        `If you have external instruments recorded, we recommend layering this on after in a DAW.`,
      ],
    },
    {
      question: "Does it record mixer effects?",
      answer: `Yes.`,
    },
    {
      question: "Can I see the DAW?",
      answer: `Yes, density.one`,
    },
    {
      question: "What if multiple DJs played?",
      answer: `The recording is automatically split into different sets for each DJ. It also handles Back to Back sets. `,
    },
    {
      question: "How do I properly shut down the device?",
      answer: `To shut down, hold the button on the top of the device.`,
    },
    {
      question:
        "Can it record the microphone, external instruments, or crowd noise?",
      answer: `No. It only records the data from the mixer and decks. If you have recorded audio, export the set after editing and layer those elements on in another DAW.`,
    },
  ],
};
