import { Box } from "@mui/material";
import { StyledAuthHeader } from "../micro/micro.styled";

import DensityLogo from "../../assets/logo-density-app.png";
import { useNavigate } from "react-router-dom";
import { ArrowBackIos } from "@mui/icons-material";

export interface AuthHeaderProps {
  showProgress?: boolean;
  step?: string;
  showBackButton?: boolean;
  width?: number;
  backButtonHandler?: () => void;
  setStep?: (step: string) => void;
  proInvite?: boolean;
  madePayment?: boolean;
}

export const AuthHeader = (props: AuthHeaderProps): JSX.Element => {
  const {
    showProgress,
    step,
    backButtonHandler,
    width,
    showBackButton = true,
    setStep = (_) => _,
    proInvite = false,
    madePayment = false,
  } = props;
  const navigate = useNavigate();
  const steps = ["1", "5", "6"];

  const canSetStep = (s: string): boolean => {
    return true;
  };

  return (
    <StyledAuthHeader>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          maxWidth: width! <= 1080 ? "400px" : "500px",
          mx: "auto",
          position: "relative",
          cursor: "pointer",
        }}
      >
        {showBackButton && (
          <Box
            sx={{
              cursor: "pointer",
              position: "absolute",
              left: "0px",
              top: "50%",
              transform: "translateY(-50%)",
            }}
            onClick={backButtonHandler}
          >
            <ArrowBackIos />
          </Box>
        )}
        <img
          style={{
            maxWidth: "175px",
            objectFit: "contain",
            cursor: "pointer",
            width: "100%",
          }}
          src={DensityLogo}
          alt={"Density"}
        />
      </Box>
      {showProgress && step && parseInt(step) !== 0 ? (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "10px",
            gap: "10px",
            justifyContent: proInvite ? "center" : "space-between",
            maxWidth: width! <= 1080 ? "200px" : "400px",
          }}
          mt={2}
          mx={"auto"}
        >
          {steps.map((stepNumber: string) => (
            <Box
              key={stepNumber}
              sx={{
                py: "8px",
                width: width! <= 1080 ? "45px" : "106px",
                borderRadius: "4px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                if (canSetStep(stepNumber)) {
                  setStep(stepNumber);
                }
              }}
            >
              <Box
                sx={{
                  height: "3px",
                  flexShrink: 0,
                  width: width! <= 1080 ? "45px" : "106px",
                  borderRadius: "4px",
                  backgroundColor:
                    parseInt(stepNumber) <= parseInt(step)
                      ? "#fcfcfc"
                      : "#42444a",
                }}
              ></Box>
            </Box>
          ))}
        </Box>
      ) : null}
    </StyledAuthHeader>
  );
};
