import { loadLocalUserSettings } from "../storage/user.settings";

// TODO: In some places this notion of platform is called "deviceType",
// yet in other places device type means just web or mobile or desktop.
// Making these consistent would add clarity.
export const getUserPlatform = (): string => {
  const userSettings = loadLocalUserSettings();

  const userSettingsDeviceType = userSettings?.deviceType;
  if (userSettingsDeviceType) {
    return userSettingsDeviceType;
  }

  const userAgent = navigator.userAgent;
  if (/android/i.test(userAgent)) {
    return "android";
  } else if (/iPad|iPhone|iPod/i.test(userAgent)) {
    return "ios";
  } else if (/Windows/i.test(userAgent)) {
    return "windows";
  } else if (/Mac/i.test(userAgent)) {
    return "mac";
  } else {
    return "unknown";
  }
};
