import { useState } from "react";
import { AuthHeader } from "../../components/AuthHeader";
import { api } from "../../api/base";
import {
  StyledAuthButton,
  StyledAuthContainer,
  StyledAuthInputFields,
  StyledAuthInputs,
  StyledPasswordChecklist,
} from "../../components/micro/micro.styled";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import {
  Typography,
  InputAdornment,
  IconButton,
  InputLabel,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";

import ValidPasswordCheckIcon from "../../assets/ValidPasswordCheck.svg";
import InvalidPasswordCheckIcon from "../../assets/InvalidPasswordCheck.svg";
import { ResetPasswordModal } from "../../components/modals/ResetPasswordModal";

export const ResetPasswordPage = () => {
  const [searchParams] = useSearchParams();
  const [newPassword, setNewPassword] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setNewPassword(e.target.value);
  };

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [enableButton, setEnableButton] = useState<boolean>(true);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const submitReset = async (
    e: React.MouseEvent<HTMLButtonElement>,
  ): Promise<void | boolean> => {
    e.preventDefault();
    const token = searchParams.get("t");
    try {
      const response = await api.post("reset-password/confirm", {
        token,
        newPassword,
      });
      if (response.status === 200) {
        setIsOpen(true);
        setHasError(false);
      }
    } catch (err) {
      setIsOpen(true);
      setHasError(true);
      console.log(err);
    }
  };

  return (
    <StyledAuthContainer>
      <AuthHeader
        step={"0"}
        showProgress={false}
        showBackButton={false}
        backButtonHandler={() => {}}
        setStep={() => {}}
      />
      <StyledAuthInputs>
        <Typography textAlign={"left"} variant={"h2"}>
          {searchParams.get("mode") === "set"
            ? "Create a new password"
            : "Reset password"}
        </Typography>
        <InputLabel htmlFor="password">Password</InputLabel>
        <StyledAuthInputFields
          value={newPassword}
          onChange={onChangeHandler}
          type={showPassword ? "text" : "password"}
          name={"password"}
          sx={{
            borderRight: "none",
            input: {
              borderRight: "none",
              borderRadius: "4px 0 0 4px",
              color: showPassword
                ? "rgba(252, 252, 252, 1)"
                : "rgba(252, 252, 252, .6)",
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment
                sx={{
                  border: "solid 1px rgba(252,252,252, 0.1)",
                  borderLeft: "none",
                  borderTopRightRadius: "4px",
                  borderBottomRightRadius: "4px",
                  marginLeft: 0,
                  padding: "18.6px",
                  paddingLeft: "0",
                }}
                position="end"
              >
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? (
                    <VisibilityOffOutlined
                      sx={{
                        height: "15px",
                        width: "15px",
                        color: "rgba(252, 252, 252, 0.6)",
                      }}
                    />
                  ) : (
                    <VisibilityOutlined
                      sx={{
                        height: "15px",
                        width: "15px",
                        color: "rgba(252, 252, 252, 0.6)",
                      }}
                    />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Typography
          textAlign={"left"}
          fontSize={11}
          color={"rgba(252, 252, 252, 0.40)"}
          variant={"body2"}
        >
          Create a password that:
        </Typography>
        <StyledPasswordChecklist
          rules={["minLength", "specialChar", "number", "capital"]}
          minLength={8}
          value={newPassword}
          onChange={(isValid) => {
            isValid ? setEnableButton(false) : setEnableButton(true);
          }}
          invalidColor={"rgba(252, 252, 252, 0.80)"}
          validColor={"#00BE00"}
          iconSize={10}
          style={{
            alignItems: "center",
          }}
          iconComponents={{
            ValidIcon: (
              <img
                style={{
                  height: 20,
                  width: 20,
                  objectFit: "contain",
                }}
                src={ValidPasswordCheckIcon}
                alt="valid input"
              />
            ),
            InvalidIcon: (
              <img
                style={{
                  height: 20,
                  width: 20,
                  objectFit: "contain",
                }}
                src={InvalidPasswordCheckIcon}
                alt="invalid input"
              />
            ),
          }}
        />
        <StyledAuthButton
          onClick={submitReset}
          disabled={enableButton}
          variant={"contained"}
        >
          {searchParams.get("mode") ? "Create password" : "Reset"}
        </StyledAuthButton>
      </StyledAuthInputs>
      <ResetPasswordModal open={isOpen} hasError={hasError} />
    </StyledAuthContainer>
  );
};
