import {
  takeEvery,
  call,
  delay,
  takeLeading,
  takeLatest,
} from "redux-saga/effects";
import { trackEvent, trackLandingView, updateFBCLID } from "./services";
import { decodeToken } from "react-jwt";
import uuid from "react-uuid";
import UAParser from "ua-parser-js";

function* trackAnalyticsEvent(action: {
  payload: any;
  type: string;
  meta: any;
}) {
  const parser = new UAParser();
  const ua = parser.getResult();
  const { type, payload, meta } = action;
  const user_settings = payload?.user_settings || {};

  // Check if the action type starts with 'DDA_'
  if (type.startsWith("DDA_")) {
    yield delay(3000);

    if (!localStorage.getItem("user_id")) {
      localStorage.setItem("user_id", uuid());
    }
    const utmParams = JSON.parse(localStorage.getItem("utm_params") || "{}");
    const decoded: any = decodeToken(
      localStorage.getItem("access_token") || "",
    );
    let user: any = {
      plan: "free",
      status: "inactive",
    };
    if (decoded) {
      user = {
        plan: decoded.plan || "free",
        status: decoded.status || "inactive",
        email: decoded.email || "",
      };
    }

    if (localStorage.getItem("refId")) {
      user.refId = localStorage.getItem("refId");
    }

    if (Object.keys(user_settings).length) {
      delete payload.user_settings;
    }

    try {
      const event = {
        event: meta.title,
        timestamp: new Date().toISOString(),
        userId: null,
        deviceType: `Web: ${ua.device.vendor} ${ua.device.model}`,
        rawData: {
          browser: ua.browser.name,
          browserVersion: ua.browser.version,
          platform: `${ua.device.vendor} ${ua.device.model}`,
          userAgent: navigator.userAgent,
          sessionId: localStorage.getItem("sessionId") || null,
        },
        payload,
        user_properties: {
          ...utmParams,
          ...user,
          user_id: localStorage.getItem("user_id") || null,
          device_id: localStorage.getItem("device_id") || null,
          os_name: ua.os.name,
          os_version: ua.os.version,
          fbclid: localStorage.getItem("fbclid") || null,
          email:
            localStorage.getItem("user_email") ||
            localStorage.getItem("user_email_login") ||
            null,
          ...user_settings,
          ...JSON.parse(localStorage.getItem("user_settings") || "{}"),
        },
      };
      // Track the event using your analytics library
      const { data } = yield call(trackEvent, event);

      // Event tracked successfully
      console.log("Event tracked:", meta.title);
    } catch (error) {
      // Handle any error that occurred during event tracking
      console.error("Error tracking event:", type, error);
    }
  }
}

function* handleLandingVisited(action: any) {
  const userDetails: any = {
    id: localStorage.getItem("user_id") || null,
    metadata: JSON.parse(localStorage.getItem("user_settings") || "{}"),
    page: window.location.href,
  };
  if (localStorage.getItem("fbclid")) {
    userDetails.fbclid = localStorage.getItem("fbclid");
  }
  if (action.payload.fbp) {
    userDetails.fbp = action.payload.fbp;
  }
  yield call(trackLandingView, "Landing Page Visited", userDetails);
}

function* handleGetDensityClicked(action: any) {
  const userDetails: any = {
    id: localStorage.getItem("user_id") || null,
    metadata: JSON.parse(localStorage.getItem("user_settings") || "{}"),
    page: window.location.href,
  };
  if (localStorage.getItem("fbclid")) {
    userDetails.fbclid = localStorage.getItem("fbclid");
  }
  if (action.payload.fbp) {
    userDetails.fbp = action.payload.fbp;
  }
  yield call(trackLandingView, "Get Density Clicked", userDetails);
}

function* handleFBCLIDUpdate(action: any) {
  yield call(updateFBCLID, action.payload.fbclid, action.payload.id);
}

export function* analyticsSaga() {
  yield takeEvery("*", trackAnalyticsEvent);
  yield takeLatest("LANDING_VISITED", handleLandingVisited);
  yield takeLatest("GET_DENSITY_CLICKED", handleGetDensityClicked);
  yield takeLeading("FBCLID_UPDATE", handleFBCLIDUpdate);
}
