import StepShape from "../../../assets/stepShape.svg";

export const Step = ({
  step,
  className = "",
}: {
  step: string;
  className?: string;
}) => {
  return (
    <div className={`flex items-center justify-center ${className}`}>
      <div className="relative flex h-[84px] w-[84px] items-center justify-center">
        <img
          className="absolute h-[82px] min-w-[82px]"
          src={StepShape}
          alt={`Step-${step}`}
        />
        <p className="rotate-[6.86deg] font-['PPRightGrotesk-WideMedium'] text-[25.6px]">
          {step}
        </p>
      </div>
    </div>
  );
};
