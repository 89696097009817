import React, { useContext, useEffect, useState } from "react";
import axios, { AxiosResponse } from "axios";
import { UserProps } from "../types/auth";
import { api } from "../api/base";
import { useLocation } from "react-router-dom";

export interface UserContextActions {
  plan: {
    planId: string;
    planName: string;
  };
  user: UserProps;
  otp: string;
  isUserLoggedIn: boolean;
  fetchingUserData: boolean;
  isPasswordSet: boolean;
  setPlan: ({ planId, planName }: { planId: string; planName: string }) => void;
  setUser: (user: UserProps) => void;
  clearUser: () => void;
  getProfile: () => Promise<void>;
  fetchOtp: () => Promise<void>;
  setOtp: (otp: string) => void;
  setIsPasswordSet: (isPasswordSet: boolean) => void;
}

interface UserContextProviderActions {
  children: React.ReactNode;
}

const UserContext = React.createContext({} as UserContextActions);

export const UserProvider: React.FC<UserContextProviderActions> = ({
  children,
}: any) => {
  const [plan, setPlan] = useState<{ planId: string; planName: string }>({
    planId: "",
    planName: "",
  });
  const [userProps, setUserProps] = useState<UserProps>({} as UserProps);
  const [otp, setOtp] = useState<string>("");
  const [isUserLoggedIn, setIsUserLoggedIn] = useState<boolean>(false);
  const [isPasswordSet, setIsPasswordSet] = useState<boolean>(false);
  const [fetchingUserData, setFetchingUserData] = useState<boolean>(true);
  const location = useLocation();

  const fetchOtp = async () => {
    try {
      const response = await api.get("/auth/otp");
      setOtp(response.data.otp);
    } catch (error) {
      console.error("Error fetching OTP:", error);
    }
  };

  const clearUser = () => {
    setIsUserLoggedIn(false);
    setIsPasswordSet(false);
    setUserProps({} as UserProps);
  };

  const getProfile = async () => {
    const tryGetProfileUser = async () => {
      const token = localStorage.getItem("access_token");
      if (!token) {
        return null;
      }

      setFetchingUserData(true);

      try {
        const { data }: AxiosResponse<any, any> = await api.get("auth/profile");
        return data?.user;
      } catch (err) {
        console.log("Failed to get profile: ", err);
        return null;
      }
    };

    try {
      const user = await tryGetProfileUser();

      const passwordIsSet = user && user.password !== "";
      setIsUserLoggedIn(passwordIsSet);
      setIsPasswordSet(passwordIsSet);

      if (user) {
        localStorage.setItem("user_email", user.email);
        setUserProps(user);
      }
    } finally {
      setFetchingUserData(false);
    }
  };

  useEffect(() => {
    getProfile();
  }, [location]);

  return (
    <UserContext.Provider
      value={{
        setPlan,
        plan,
        user: userProps,
        setUser: setUserProps,
        otp,
        fetchOtp,
        setOtp,
        clearUser,
        getProfile,
        isUserLoggedIn,
        fetchingUserData,
        isPasswordSet,
        setIsPasswordSet,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = (): UserContextActions => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error("useUserContext must be used within an UserProvider");
  }

  return context;
};
