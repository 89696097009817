import { api } from "../../../../api/base";

export const createSession = async (userId: any) => {
  try {
    const { data } = await api.post("/session", {
      userId,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchSession = async (userId: any) => {
  try {
    const { data } = await api.get(`/session/latest/${userId}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const downloadApp = async (
  email: string,
  user_id: string,
  location: string,
) => {
  try {
    const { data } = await api.post("/auth/download", {
      email,
      os: "universal",
      location,
      user_id,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const setReminder = async (user_id: string, reminder: string) => {
  try {
    const { data } = await api.post(`/klaviyo/set-reminder`, {
      user_id,
      reminder,
      date: new Date().toISOString(),
    });

    return data;
  } catch (error) {
    throw error;
  }
};

export const sendDownloadEmail = async (email: string) => {
  try {
    const { data } = await api.post(`/klaviyo/reminder-download`, {
      email,
      utmParams: JSON.parse(localStorage.getItem("utm_params") || "{}"),
    });

    return data;
  } catch (error) {
    throw error;
  }
};
