import { getUserPlatform } from "./user.info";

const getUserEmail = (): string | null => {
  return localStorage.getItem("user_email") || null;
};

const getUserId = (): string | null => {
  return localStorage.getItem("user_id") || null;
};

const gtmUpdateVariables = () => {
  window.dataLayer.push({
    user_platform: getUserPlatform(),
    user_email: getUserEmail(),
    user_id: getUserId(),
  });
};

export enum GTMEvent {
  LandingPageVisited = "WebEvent_LandingPageVisited",
  Registered = "WebEvent_Registered",
  Download = "WebEvent_Download",
  MagicLogin = "WebEvent_MagicLogin",
}

export const gtmTriggerEvent = (eventName: string) => {
  // Always refresh these to their latest values before the event.
  gtmUpdateVariables();

  window.dataLayer.push({
    event: eventName,
  });
};
