import React, { useEffect } from "react";
import { Outlet, useSearchParams } from "react-router-dom";
import { StyledAppContainer } from "./micro.styled";
import { useDispatch } from "react-redux";
import { decodeToken } from "react-jwt";
import uuid from "react-uuid";

import { appStart } from "../../redux/actions/app";
import { loadLocalReferralInfo } from "../../storage/referral.info";
import { markSessionFlagIfAbsent } from "../../storage/session.flags";
import { analyticsEvent } from "../../redux/actions/analytics";
import { loadLocalUtmParams } from "../../storage/utm.params";

export const AppContainer = (): JSX.Element => {
  const [searchParams] = useSearchParams();
  const token: any = localStorage.getItem("access_token");
  const decoded: any = decodeToken(token);
  const userId = uuid();
  if (!localStorage.getItem("user_id")) {
    localStorage.setItem("user_id", userId);
  }
  const uId = decoded?.userId || localStorage.getItem("user_id") || userId;

  const dispatch = useDispatch();
  dispatch(appStart(uId));

  useEffect(() => {
    const fbclid = searchParams.get("fbclid");
    const refId = searchParams.get("ref");
    const storedFbclid = localStorage.getItem("fbclid");
    const accessToken = localStorage.getItem("access_token");

    if (refId) {
      localStorage.setItem("refId", refId);
    }

    if (fbclid) {
      if (accessToken && (!storedFbclid || storedFbclid !== fbclid)) {
        localStorage.setItem("fbclid", fbclid);
        dispatch({
          type: "FBCLID_UPDATE",
          payload: { fbclid, id: uId },
        });
      } else if (!accessToken || storedFbclid !== fbclid) {
        localStorage.setItem("fbclid", fbclid);
      }
    }
  }, [searchParams, uId, dispatch]);

  useEffect(() => {
    const referralInfoOpt = loadLocalReferralInfo();
    const referralInfoRefId = referralInfoOpt?.ref_id;
    if (!referralInfoRefId) {
      return;
    }

    const isFirstEvent = markSessionFlagIfAbsent(
      `event_referral_${referralInfoRefId}`,
    );
    if (!isFirstEvent) {
      return;
    }

    const utmParamsOpt = loadLocalUtmParams();
    const utmMedium = utmParamsOpt?.utm_medium || "";
    const utmSource = utmParamsOpt?.utm_source || "";
    const utmContent = utmParamsOpt?.utm_content || "";
    const utmCampaign = utmParamsOpt?.utm_campaign || "";
    const isUserReferralLink =
      utmMedium === "referral" &&
      utmSource === "density" &&
      utmContent === referralInfoRefId &&
      utmCampaign;
    if (!isUserReferralLink) {
      return;
    }

    dispatch(
      analyticsEvent("EVENT", "Referral Link Visited", {
        location: `${window.location.pathname}`,
        refId: referralInfoRefId,
        reward: utmCampaign,
      }),
    );
  }, [searchParams, dispatch]);

  return (
    <StyledAppContainer>
      <Outlet />
    </StyledAppContainer>
  );
};
