import { api } from "../../../../api/base";

export const trackEvent = async (event: any) => {
  try {
    const { data } = await api.post("/analytics/events", [event]);
    return data;
  } catch (error) {
    throw error;
  }
};

export const trackLandingView = async (event: string, userDetails: any) => {
  try {
    const { data } = await api.post("/auth/landing", {
      event,
      userDetails,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateFBCLID = async (fbclid: string, id: string) => {
  try {
    const { data } = await api.post("/auth/fbclid", {
      fbclid,
      id,
    });
    return data;
  } catch (error) {
    throw error;
  }
};
