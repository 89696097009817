import { Location, Path } from "react-router-dom";

export const tryGetRedirection = (
  location: Location,
  searchParams: URLSearchParams,
): Partial<Path> | null => {
  const redirectToken = searchParams.get("r");
  if (!redirectToken) {
    return null;
  }

  let target: RedirectTarget = {
    path: location.pathname,
    search: new URLSearchParams(searchParams),
  };

  const redirectors: [string, Redirector][] = [
    ["a", redirectUserReferralGroupA],
    ["b", redirectUserReferralGroupB],
    ["u", redirectUserReferralGroupUnknown],
  ];
  let foundRedirector = false;
  for (const [prefix, redirector] of redirectors) {
    if (redirectToken.startsWith(prefix)) {
      const remainder = redirectToken.substring(prefix.length);
      redirector(target, remainder);

      foundRedirector = true;
      break;
    }
  }

  if (!foundRedirector) {
    console.warn(`Unknown redirect: ${redirectToken}`);
  }

  // Done last to ensure we never enter a redirect loop.
  target.search.delete("r");

  return {
    pathname: target.path,
    search: target.search.toString(),
  };
};

interface RedirectTarget {
  path: string;
  search: URLSearchParams;
}

type Redirector = (target: RedirectTarget, refId: string) => void;

const redirectUserReferralGroupA = (target: RedirectTarget, refId: string) => {
  redirectUserReferralGroup(target, refId, "hardware");
};

const redirectUserReferralGroupB = (target: RedirectTarget, refId: string) => {
  redirectUserReferralGroup(target, refId, "premium");
};

const redirectUserReferralGroupUnknown = (
  target: RedirectTarget,
  refId: string,
) => {
  redirectUserReferralGroup(target, refId, "unknown");
};

const redirectUserReferralGroup = (
  target: RedirectTarget,
  refId: string,
  reward: string,
) => {
  addSearchParamIfAbsent(target, "ref_id", refId);
  addSearchParamIfAbsent(target, "utm_medium", "referral");
  addSearchParamIfAbsent(target, "utm_source", "density");
  addSearchParamIfAbsent(target, "utm_content", refId);
  addSearchParamIfAbsent(target, "utm_campaign", reward);
};

const addSearchParamIfAbsent = (
  target: RedirectTarget,
  key: string,
  value: string,
) => {
  if (!target.search.has(key)) {
    target.search.set(key, value);
  }
};
