export const tryReadParamGroup = (
  searchParams: URLSearchParams,
  keys: string[],
): any | null => {
  let hasNonEmptyParam = false;
  const readParam = (name: string): string => {
    const result = searchParams.get(name) || "";
    hasNonEmptyParam = hasNonEmptyParam || !!result;

    return result;
  };

  let result: any = {};
  for (const key of keys) {
    result[key] = readParam(key);
  }

  return hasNonEmptyParam ? result : null;
};
