import { tryReadParamGroup } from "../utils/param.group";
import { loadLocalJSON, storeLocalJSON } from "./local.storage";

export interface ReferralInfo {
  ref_id: string;
  ref_affiliate: string;
}

const tryGetNonEmptyReferralInfo = (
  searchParams: URLSearchParams,
): ReferralInfo | null => {
  return tryReadParamGroup(searchParams, ["ref_id", "ref_affiliate"]);
};

export const rememberReferralInfo = (searchParams: URLSearchParams) => {
  const referralInfoOpt = tryGetNonEmptyReferralInfo(searchParams);
  if (referralInfoOpt) {
    storeLocalReferralInfo(referralInfoOpt);
  }
};

export const storeLocalReferralInfo = (referralInfo: ReferralInfo) => {
  storeLocalJSON("referral_info", referralInfo);
};

export const loadLocalReferralInfo = (): any | null => {
  return loadLocalJSON("referral_info");
};
