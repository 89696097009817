import { Box, Button, Typography, Modal, Divider } from "@mui/material";

import { StyledAccountContent } from "../../components/micro/micro.styled";

import CloseModalIcon from "../../assets/CloseModalIcon.svg";
import CancelPlanSuccess from "../../assets/CancelPlanSuccess.svg";

export interface CancelPlanSuccessModalProps {
  open: boolean;
  handleClose: () => void;
  handleRenewal: () => void;
  subscriptionPrice: string;
  action: string;
}

export const ConfirmRenewalModal = ({
  open,
  handleClose,
  handleRenewal,
  subscriptionPrice,
  action,
}: CancelPlanSuccessModalProps): JSX.Element => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      sx={{
        backdropFilter: "blur(3px)",
        minWidth: "350px",
      }}
    >
      <Box
        gap={"20px"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        display={"flex"}
        height={"100%"}
        width={"100%"}
      >
        <StyledAccountContent sx={{ gap: "16px" }}>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"flex-end"}
          >
            <button
              onClick={handleClose}
              style={{
                border: "none",
                background: "transparent",
                cursor: "pointer",
                padding: "0",
                height: "20px",
                width: "20px",
              }}
            >
              <img
                style={{
                  maxWidth: "20px",
                  objectFit: "contain",
                }}
                src={CloseModalIcon}
                alt="close modal"
              />
            </button>
          </Box>
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <img
              src={CancelPlanSuccess}
              style={{
                height: "80px",
                width: "80px",
                objectFit: "contain",
                marginBottom: "20px",
              }}
              alt="success"
            />
            <Typography variant={"h2"}>{action} your subscription</Typography>
            <Typography
              my={"16px"}
              textAlign={"center"}
              variant={"body1"}
              color={"rgba(252, 252, 252, 0.6)"}
              letterSpacing={"0.4px"}
              fontSize={11}
              lineHeight={"16px"}
              fontWeight={400}
            >
              Would you like to confirm the{" "}
              {action.toLowerCase() === "renew" ? "renewal" : "reactivation"} of
              your subscription for {subscriptionPrice}?
            </Typography>
          </Box>

          <Divider
            sx={{
              width: "100%",
              my: "16px",
            }}
          />
          <Box display={"flex"} justifyContent={"space-between"} gap={2}>
            <Button
              fullWidth
              sx={{
                backgroundColor: "#37383c",
                color: "#F3F3F5",
                borderColor: "#37383c",
                "&:hover": {
                  borderColor: "#37383c",
                },
              }}
              variant={"outlined"}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              fullWidth
              variant={"contained"}
              onClick={() => handleRenewal()}
            >
              Confirm{" "}
              {action.toLowerCase() === "renew" ? "renewal" : "reactivation"}
            </Button>
          </Box>
        </StyledAccountContent>
      </Box>
    </Modal>
  );
};
