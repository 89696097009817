import { Typography } from "@mui/material";
import Lottie from "lottie-react";
import Loops from "../../assets/loops.png";
import EQ from "../../assets/eq-automation.png";
import EQMobile from "../../assets/eq_mobile 1.png";
import TransitionPresets from "../../assets/transition-presets.png";
import TransitionPresetsAnimation from "../../assets/lottie/Page2+++.json";
import LoopsAnimation from "../../assets/lottie/Page3_BIG_loop2.json";
import FX from "../../assets/fx.png";
import FXMobile from "../../assets/fx_mobile 1.png";
import {
  StyledDensityFeatureFullImageItem,
  StyledDensityFeaturesContainer,
  StyledDensityFeaturesCroppedImageItem,
  StyledDensityFeaturesWrapper,
} from "../../pages/LandingPage/landing.styled";

export interface DensityFeaturesProps {
  width: number;
}

export const DensityFeatures = ({ width }: DensityFeaturesProps) => {
  return (
    <StyledDensityFeaturesContainer>
      <StyledDensityFeaturesWrapper>
        <StyledDensityFeatureFullImageItem>
          {/* <img src={TransitionPresets} alt="" /> */}
          <Lottie animationData={TransitionPresetsAnimation} loop={true} />
          <Typography variant="subtitle2">Transition Presets</Typography>
          <p>
            Cycle between different types of transitions and then adjust from
            there. This saves around 20 clicks per transition.
          </p>
        </StyledDensityFeatureFullImageItem>
        <StyledDensityFeaturesCroppedImageItem>
          <Typography variant="subtitle2">EQ Automation Lanes</Typography>
          <p>Every track loads with EQ automation lanes ready to go.</p>
          <img src={width > 769 ? EQ : EQMobile} alt="" />
        </StyledDensityFeaturesCroppedImageItem>
      </StyledDensityFeaturesWrapper>
      <StyledDensityFeaturesWrapper>
        <StyledDensityFeaturesCroppedImageItem>
          <Typography variant="subtitle2">FX</Typography>
          <p>Every mixer effect is included.</p>
          <img src={width > 769 ? FX : FXMobile} alt="" />
        </StyledDensityFeaturesCroppedImageItem>
        <StyledDensityFeatureFullImageItem>
          {/* <img src={Loops} alt="" /> */}
          <Lottie animationData={LoopsAnimation} loop={true} />
          <Typography variant="subtitle2">Create and release loops</Typography>
          <p>
            Drag to create a loop. Click to release the loop and play the rest
            of the song.
          </p>
        </StyledDensityFeatureFullImageItem>
      </StyledDensityFeaturesWrapper>
    </StyledDensityFeaturesContainer>
  );
};
