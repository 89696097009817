import { useLocation } from "react-router-dom";

export const useOnboarding = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const plan = searchParams.get("plan");
  const state = searchParams.get("state");

  const isComingFromHardware = plan ? plan.includes("hardware") : false;
  const isFromSuccessfulPurchase = state && state === "succeeded";

  return {
    isComingFromHardware,
    isFromSuccessfulPurchase,
  };
};
