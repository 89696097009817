import { api } from "./base";

// TODO: Deduplicate type definitions from backend.
export interface CampaignsInfo {
  affiliate: AffiliateInfo;
  user_referral: UserReferralInfo;
}

export interface CampaignInfo {
  ref_id: string;
  referral_link: string;
}

export interface AffiliateInfo extends CampaignInfo {}

export interface UserReferralInfo extends CampaignInfo {
  active_referrals: number;
  required_referrals: number;
  reward: string;
}

export const tryQueryCampaignsInfo =
  async (): Promise<CampaignsInfo | null> => {
    try {
      const response = await api.get("promoter/campaigns");
      return parseCampaignsInfo(response.data);
    } catch (error) {
      console.warn("Failed to fetch referral info:", error);
      return null;
    }
  };

export const tryEnterUserReferralCampaign = async (
  reward: string,
): Promise<CampaignsInfo | null> => {
  try {
    const response = await api.post(
      `promoter/campaigns/user_referral?reward=${reward}`,
    );
    return parseCampaignsInfo(response.data);
  } catch (error) {
    console.warn("Failed to fetch referral info:", error);
    return null;
  }
};

const parseCampaignsInfo = (data: any): CampaignsInfo => {
  return {
    affiliate: parseAffiliateInfo(data?.affiliate),
    user_referral: parseUserReferralInfo(data?.user_referral),
  };
};

const parseAffiliateInfo = (data: any): AffiliateInfo => {
  return {
    ...parseCampaignInfo(data),
  };
};

const parseUserReferralInfo = (data: any): UserReferralInfo => {
  return {
    ...parseCampaignInfo(data),
    active_referrals: Number(data?.active_referrals) || 0,
    required_referrals: Number(data?.required_referrals) || 0,
    reward: data?.reward || 0,
  };
};

const parseCampaignInfo = (data: any): CampaignInfo => {
  return {
    ref_id: data?.ref_id || "",
    referral_link: data?.referral_link || "",
  };
};
