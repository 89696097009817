import React from "react";
import { Box, Button, Typography, Modal, Divider } from "@mui/material";

import { StyledAccountContent } from "../../components/micro/micro.styled";

import CloseModalIcon from "../../assets/CloseModalIcon.svg";
import CancelPlanSuccess from "../../assets/CancelPlanSuccess.svg";

import { formatDateFromTimestamp } from "../../pages/Profile";

export interface CancelPlanSuccessModalProps {
  open: boolean;
  handleClose: () => void;
  subscriptions: any;
  renewSubscription: () => void;
}

export const CancelPlanSuccessModal = ({
  open,
  handleClose,
  subscriptions,
  renewSubscription,
}: CancelPlanSuccessModalProps): JSX.Element => {
  const handleKeepSubscription = () => {
    renewSubscription();
    handleClose();
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      sx={{
        backdropFilter: "blur(3px)",
        minWidth: "350px",
      }}
    >
      <Box
        gap={"20px"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        display={"flex"}
        height={"100%"}
        width={"100%"}
      >
        <StyledAccountContent sx={{ gap: "16px" }}>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"flex-end"}
          >
            <button
              onClick={handleClose}
              style={{
                border: "none",
                background: "transparent",
                cursor: "pointer",
                padding: "0",
                height: "20px",
                width: "20px",
              }}
            >
              <img
                style={{
                  maxWidth: "20px",
                  objectFit: "contain",
                }}
                src={CloseModalIcon}
                alt="close modal"
              />
            </button>
          </Box>
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <img
              src={CancelPlanSuccess}
              style={{
                height: "80px",
                width: "80px",
                objectFit: "contain",
                marginBottom: "20px",
              }}
              alt="success"
            />
            <Typography variant={"h2"}>
              Your subscription was cancelled
            </Typography>
            <Typography
              my={"16px"}
              textAlign={"center"}
              variant={"body1"}
              color={"rgba(252, 252, 252, 0.6)"}
              letterSpacing={"0.4px"}
              fontSize={11}
              lineHeight={"16px"}
              fontWeight={400}
            >
              You have access to Density until{" "}
              {subscriptions?.status === "non_renewing"
                ? formatDateFromTimestamp(subscriptions?.current_term_end)
                : subscriptions.trial_end
                  ? formatDateFromTimestamp(subscriptions.trial_end)
                  : formatDateFromTimestamp(subscriptions.end_date)}
            </Typography>
            <Typography
              maxWidth={"400px"}
              textAlign={"center"}
              variant={"body1"}
              color={"rgba(252, 252, 252, 0.6)"}
              letterSpacing={"0.4px"}
              fontSize={11}
              lineHeight={"16px"}
              fontWeight={400}
            >
              When your subscription expires, you will lose access to your sets.
              They are saved locally on your computer so you can continue
              editing them by renewing your subscription.
            </Typography>
          </Box>

          <Divider
            sx={{
              width: "100%",
              my: "16px",
            }}
          />
          <Box display={"flex"} justifyContent={"space-between"} gap={2}>
            <Button
              fullWidth
              sx={{
                backgroundColor: "#37383c",
                color: "#F3F3F5",
                borderColor: "#37383c",
                "&:hover": {
                  borderColor: "#37383c",
                },
              }}
              variant={"outlined"}
              onClick={handleKeepSubscription}
            >
              Keep subscription
            </Button>
            <Button fullWidth variant={"contained"} onClick={handleClose}>
              Go back to account page
            </Button>
          </Box>
        </StyledAccountContent>
      </Box>
    </Modal>
  );
};
