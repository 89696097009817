import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import { reportWebVitals } from "./reportWebVitals";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import "./constants/global.css";

export const router = createBrowserRouter([{ path: "*", element: <App /> }]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
